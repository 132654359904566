export const MODELS_IDS_BY_NAME = {
  Eterno: {
    'Eterno Fabric Armless Sofa': '14f0c77d-5562-4bf3-9ef2-8c24a775764b',
    'Eterno Fabric Left Arm Sofa': '5a9b6f80-6a19-4e84-810e-9b5104f6b747',
    'Eterno Fabric Right Arm Sofa': '66d72180-99f7-439d-9879-1506f2d14b9c',
    'Eterno Fabric Left Chaise': '0c23c329-0daa-4fc4-a6e1-7befcd5ea31b',
    'Eterno Fabric Right Chaise': '9477763c-b378-4354-8293-ff7ae0c6b11f',
    'Eterno Modular Sofa Console': 'b9b4cfda-b304-4631-87d4-eccbc2047e1c',
    'Eterno Fabric Storage Ottoman': '48f75537-e484-4d50-a6b9-4e6ab6952b50',
    'Eterno Fabric Armless Wedge Sofa': 'eefd8c50-57f3-4d59-b68e-94eea4713ba3',
  },

  Levin: {
    'Levin Fabric Chair': 'e60388f5-5653-4deb-bb0a-ef0ab621498b',
    'Levin Fabric Ottoman': 'e34596e2-b31d-4a07-8e01-22a5b999f577',
    'Levin Fabric Wedge': '6e08641f-02b4-44be-8dce-d464f89ae668',
  },
};

const dimensionsDataTable = 'd2ecdb20-9965-405f-a9dd-31458dfa81eb';
export const dimensionsApi = `${__THREEKIT_HOST__}/api/datatables/${dimensionsDataTable}/rows?bearer_token=${__THREEKIT_AUTH_TOKEN__}&all=true`;
