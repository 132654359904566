import { isShadowPlane } from '../../helpers';

export const SELECTION_COLORS = {
  VALID: '#00BFFF',
  INVALID: '#ff0000',
};

export function toggleHighlightError(errorExists) {
  window.threekit.api.selectionSet.setStyle({
    outlineColor: errorExists
      ? SELECTION_COLORS.INVALID
      : SELECTION_COLORS.VALID,
  });
}

function filter(node) {
  // Is there a better way to handle this?
  return !isShadowPlane(node.name);
}

// Ideally, we could simply pass just the Model Null id to the player's
// selection and it would handle highlighting all parts of the Model. However,
// there is a deficiency in the player's highlighting at the moment where it
// will not traverse into an Item node's reference (ex. if a rule sets a model
// asset from an input catalog item), and thus won't highlight all of the
// Model's parts. The following code works around that by recursively traversing
// inside a Model to add all PolyMesh nodes to the player's selection for
// highlighting.
export async function setNodeHighlighting(
  nodeId,
  highlight,
  highlightChildren = false
) {
  const node = window.threekit.api.store.get('sceneGraph').nodes[nodeId];
  if (!node) return;
  const { type, children } = node;

  if (filter(node)) {
    if (type === 'PolyMesh') {
      if (highlight) window.threekit.api.selectionSet.add(nodeId);
      else window.threekit.api.selectionSet.remove(nodeId);
    } else if (type === 'Model') {
      let instanceId;

      const assetQuery = {
        id: nodeId,
        plug: 'Null',
        property: 'asset',
      };
      const asset = window.threekit.api.scene.get(assetQuery);
      if (typeof asset === 'string') instanceId = asset;
      else {
        instanceId = await window.threekit.api.player.getAssetInstance({
          id: nodeId,
          plug: 'Null',
          property: 'asset',
        });
      }
      await setNodeHighlighting(instanceId, highlight, true);
    } else if (type === 'Item') {
      const instanceId = await window.threekit.api.player.getAssetInstance({
        id: nodeId,
        plug: 'Proxy',
        property: 'asset',
      });
      await setNodeHighlighting(instanceId, highlight, true);
    }
  }

  if (highlightChildren)
    return Promise.all(
      children.map(async (id) => setNodeHighlighting(id, highlight, true))
    );
}
