import {getMainSet} from '../grouping';
import {getBoundingBoxAPI} from "./boundingBox";
import {frameNodes, frameScene} from "../camera";
import {getItemIds, getItems, getMeshesForItems} from "../items";
import {MEASUREMENT_NAME, data} from "./constants";
import {getAllItemGroups, updateMainSet} from "../placement/attachments";
import fetchDimensionsTable from "../placement/fetchDimensionsTable";

const set = new Set();

export function setOpenState(value) {
  data.isOpen = value;
}

export function addToMeasurableTargets(id) {
  set.add(id);
}

export function removeFromMeasurableTargets(id) {
  set.delete(id);
}

export function getMeasurableTargets(){
  return Array.from(set);
}

function nodeIsChildOf(node, parentId) {
  const {player} = window.threekit.api;

  if (!node || !node.parent) {
    return false
  }

  if (node.parent === parentId) {
    return true
  }

  return nodeIsChildOf(player.sceneGraph.nodes[node.parent], parentId)
}

function filterChilren(predicate, parent) {
  const {player} = window.threekit.api;
  return Object.values(player.sceneGraph.nodes).filter(node=>nodeIsChildOf(node, parent)).filter(predicate).map(node => node.id)
}

function getBedMeasurableTargets() {
  const {player} = window.threekit.api;
  const models = Object.values(player.sceneGraph.evaluatedNodes).map(en => en.node).filter(node => node.type === 'Model')
  const notAccessories = models.filter(node => node.name !== 'AccessoriesNullModel')
  const accessories = models.filter(node => node.name === 'AccessoriesNullModel')
  const drawers = Object.values(player.sceneGraph.nodes).filter(node => {
    return ['OpenDrawer', 'CloseDrawer', 'open', 'close'].includes(node.name);
  }).map(node => node.id)
  // console.log(accessories, notAccessories)
  const meshes = notAccessories.map(node=>filterChilren(node=>node.type === 'PolyMesh', node.id)).flat()
  // .map(node => node.id)
  // console.log('!accessory.meshes', meshes)
  const accessoryMeshes = accessories.map(node=>filterChilren(node=>node.type === 'PolyMesh', node.id)).flat()
  // console.log('accessory.meshes', accessoryMeshes)

  const otherMeshes = meshes.filter(id => {
    if (accessoryMeshes.includes(id)) {
      return false
    }

    if (data.isOpen) {
      return true;
    }

    if (drawers.find(drawerId => nodeIsChildOf(player.sceneGraph.nodes[id], drawerId))) {
      return false
    }

    return true
  })

  return otherMeshes
}

function getMeasureTargetIds() {
  return data.category === 'Bed' ? getBedMeasurableTargets() : getMainSet();
}

function setMeasurementWidgetLabels({width, height, depth} = {}){
  const {scene} = window.threekit.api;
  scene.set(
    {name: 'Box Dimensions', plug: 'Measurement', property: 'targets'},
    getBedMeasurableTargets()
  );
  scene.set(
    {name: 'Box Dimensions', plug: 'Measurement', property: 'xLabel'},
    `${width} cm`
  );
  scene.set(
    {name: 'Box Dimensions', plug: 'Measurement', property: 'yLabel'},
    `${height} cm`
  );
  scene.set(
    {name: 'Box Dimensions', plug: 'Measurement', property: 'zLabel'},
    `${depth} cm`
  );
}

function getConfiguration(){
  return [...window.threekit.configurator.getItems().values()][0].configuration;
}

function getBedBase() {
  const configuration = getConfiguration();
  const name = configuration?.Bedbase?.en;
  const collection = configuration?.Bedbase?.collection;
  return{
    name, collection
  }
}

function getBedHeadboard() {
  const configuration = getConfiguration();
  const name = configuration?.Headboard?.en;
  const collection = configuration?.Headboard?.collection;
return {

  name, collection
}
}

async function getBedPartDimensions(collection, name){
  const rows = await fetchDimensionsTable();
  return rows.find(row => {
    return row.Collection_Name === collection && row.Product_Name === name
  });
}

async function getBedBaseDimension() {
  const {collection, name} = getBedBase();
  const dimensions = await getBedPartDimensions(collection, name);
  if (!dimensions) {
    return {Width: 0, Height: 0, Depth: 0, Standalone_Depth: 0};
  }
  return dimensions
}

async function getBedHeadboardDimension() {
  const {collection, name} = getBedHeadboard();
  const dimensions = await getBedPartDimensions(collection, name);
  if (!dimensions) {
    return {Width: 0, Height: 0, Depth: 0, Standalone_Depth: 0};
  }
  return dimensions
}

async function updateBedMeasurement() {
  const base = await getBedBaseDimension();
  const headboard = await getBedHeadboardDimension();
  setMeasurementWidgetLabels({
    width: Math.max(base.Width, headboard.Width),
    height: Math.max(base.Height, headboard.Height),
    depth: base.Depth ? base.Depth + headboard.Depth : headboard.Standalone_Depth,
  });
}

export async function updateMeasureTargets() {
  if (data.category === 'Bed') {
    await updateBedMeasurement();
  } else {
    updateMainSet();
  }
}

export function setMeasurableCategory(category) {
  data.category = category
}

export async function showMeasurement(showDimensions, category) {
  const {scene} = window.threekit.api;
  data.category = category;
  data.showDimensions = showDimensions;
  await updateMeasureTargets();
  const measurementNodes = scene.filterNodes({name: MEASUREMENT_NAME})
  measurementNodes.forEach((id) => {
    scene.set({
      id,
      plug: 'Properties',
      property: 'visible',
    }, showDimensions);
  });
}

export function getGroupMeasurementList() {
  const measurement = getBoundingBoxAPI();

  if (data.category === 'Bed') {
    return [measurement.getModelSize(getMeasureTargetIds())]
  }

  const groups = getAllItemGroups();
  const {scene} = window.threekit.api;
  scene.set({hierarchical: true, tags: [MEASUREMENT_NAME], plug: 'Properties', property: 'visible'}, true);
  const result = groups.map(list => {
    return measurement.getMeasureBoxSize(list)
  });
  scene.set({hierarchical: true, tags: [MEASUREMENT_NAME], plug: 'Properties', property: 'visible'}, false);
  return result;
}

export function getMeasurements() {
  const {THREE} = window.threekit.api;
  const {store} = window.threekit.api.player;
  const ids = getMeasureTargetIds();
  const measurement = getBoundingBoxAPI();
  const box = measurement.getNodesBoundingBox(store, ids);

  function formatLength(value) {
    return `${(value * 100).toFixed(0)} cm`
  }

  if(!box){
    return {
      width: formatLength(0),
      height: formatLength(0),
      length: formatLength(0),
    }
  }

  const size = new THREE.Vector3();
  box.getSize(size);

  return {
    width: formatLength(size.x),
    height: formatLength(size.y),
    length: formatLength(size.z),
  };
}

export function frameSceneAroundItems() {
  return frameScene(Array.from(getItems().keys()))
}

export async function frameItems(){
  const nodeIds = await getMeshesForItems(getItemIds(), {
    excludeAccessories: true,
  });
  frameNodes(nodeIds);
}
