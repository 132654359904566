/* global __THREEKIT_AUTH_TOKEN__,  __THREEKIT_HOST__, __THREEKIT_ORG__ */

import { getFloor } from '../room';
import { getItems } from '../items';
import { getAttachments, decomposeForTransformPlug } from '../placement';
import { getWorldTransform } from '../../helpers';
import axios from 'axios';

let editConfigId = '';

export async function saveConfiguration(designConfig) {
  const configuration = getConfigFromState();
  const { id } = await postConfiguration(configuration, designConfig);
  return id;
}

export function saveEditConfiguration(id) {
  editConfigId = id;
  window.threekit.editConfigId = id;
}

export function getEditConfiguration() {
  return editConfigId;
}

const postConfiguration = (configuration, designConfig) => {
  const url = `${__THREEKIT_HOST__}/api/configurations?tags=Levin&bearer_token=${__THREEKIT_AUTH_TOKEN__}`;
  const body = new FormData();
  body.append('orgId', __THREEKIT_ORG__);
  body.append('productId', window.threekit.api.assetId);
  // TO DO: what should productVersion be?
  body.append('productVersion', 'test_version');
  body.append('variant', JSON.stringify(configuration));
  body.append('metadata', JSON.stringify(designConfig));

  return fetch(url, {
    method: 'post',
    body,
  })
    .then((res) => res.json())
    .catch((e) => console.error(e));
};

export const getSavedConfiguration = async ({
  designId = '',
  configId = '',
}) => {
  // pass designId (for save DESIGN ID) or configId (for go back/collection player change)
  let config_id = configId;
  try {
    if (!!designId) {
      const metadata = JSON.stringify({ designId }); // find configId with metadata
      const configListUrl = `${__THREEKIT_HOST__}/api/configurations/?bearer_token=${__THREEKIT_AUTH_TOKEN__}&orgId=${__THREEKIT_ORG__}&metadata=${metadata}`;

      const configList =
        (await axios(configListUrl))?.data?.configurations || []; // get all config list

      const config = configList[0];

      if (!config) return { hasConfigError: true };
      config_id = config.id;
    }

    const url = `${__THREEKIT_HOST__}/api/configurations/${config_id}?bearer_token=${__THREEKIT_AUTH_TOKEN__}`;

    const output = fetch(url, {
      method: 'get',
    })
      .then((res) => res.json())
      .catch((e) => console.error(e));
    return output;
  } catch (error) {
    console.log('err getSavedConfiguration = ', error);
  }
};

const getConfigFromState = () => {
  const floor = getFloor();
  const items = [];
  const attachments = Object.values(getAttachments());

  getItems().forEach((value, itemId) => {
    const item = { ...value };

    // It is necessary to store items' world transforms rather than local
    // transforms, since accessories are reparented to the items they are
    // attached to
    const worldTransform = getWorldTransform(itemId);
    const { translation, rotation } = decomposeForTransformPlug(worldTransform);
    item.position = {
      translation,
      rotation,
    };
    items.push(item);
  });

  return { floor, items, attachments };
};
