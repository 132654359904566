import {dimensionsApi} from "../../constants";

export default async function fetchDimensionsTable() {
  if (!fetchDimensionsTable.rows) {
    if (!fetchDimensionsTable.promise) {
      fetchDimensionsTable.promise = fetch(dimensionsApi).then(response => response.json());
    }
    const response = await fetchDimensionsTable.promise;
    if (response.rows) {
      fetchDimensionsTable.rows = response.rows.map(row => row.value);
    } else {
      fetchDimensionsTable.rows = [];
      fetchDimensionsTable.errorCode = response.status;
    }
  }
  return fetchDimensionsTable.rows;
}
