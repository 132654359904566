/**
 * Returns all children or linked nodes. Useful for moving down the hierarchy.
 */
function traverseNode(node, evalNode) {
  if (!node || !evalNode) {
    return [];
  }
  return evalNode.getHierarchicalChildren();
}

/**
 * Traverse all descendants of a given node (defaults to 'Objects'), passing ids
 * to a callback.
 * @param {*} store
 * @param {*} optionalId
 * @param {*} visibleOnly
 * @param {*} callback
 */
export function traverseHierarchy(store, rootId, visibleOnly = false, callback, rootModelId // used during internal recursion
) {
  if (!callback) {
    console.error('traverseHierarchy: no callback provided');
    return;
  }
  const sceneGraph = store.get('sceneGraph');
  const nodes = sceneGraph.nodes;
  // const propPlug = nodes[sceneId] && nodes[sceneId].plugs.Properties;
  // const hierarchicalVisibility =
  //   propPlug && propPlug[0] && propPlug[0].hierarchyVisibility === 'Enable';
  const node = nodes[rootId];
  if (!node)
    return;
  const evalNode = sceneGraph.evaluatedNodes[node.id];
  if (visibleOnly && evalNode && evalNode.visible === false) {
    return;
  }
  const skipBranch = callback(node, rootModelId) === false;
  // Only traverse child hierarchy if we don't care about visibility, or if
  // descendants could be visible.
  if (!skipBranch
    // !visibleOnly ||
    // !hierarchicalVisibility ||
    // (hierarchicalVisibility && nodeVisible)
  ) {
    const children = traverseNode(node, evalNode);
    // rewritten from a for loop to a for-let loop for tslint
    for (const child of children) {
      traverseHierarchy(store, child, visibleOnly, callback, rootModelId);
    }
  }
}
