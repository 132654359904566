import { sacItems } from './sac';
import { squattoman } from './squattoman';
import { throwPillow } from './throwPillows';
import { sactionalItems } from './sactional';
import {
  getRootId,
  traverseHierarchy,
  isShadowPlane,
  setConfigOnModel,
} from '../../helpers';
// import { setConfigOnModel } from '../../../../../demo-app/src/utils';
import { itemWillBeRemoved, initItemPlacement } from '../placement';
import { deselectItems, clearSelection, selectItem } from '../selection';
import { updateCapacity } from '../capacity';
import { addColliders, removeColliders } from '../collision';
import { baFabricToSceneConfig, setSceneConfiguration } from '../room';
import { MODELS_IDS_BY_NAME } from '../../constants';
import {
  addToMeasurableTargets,
  removeFromMeasurableTargets,
} from '../measurement/measurement';
import { updateMainSet } from '../placement/attachments';
import { frameScene } from '../camera';
import { registerConnectorsForItem } from '../placement/sizeUpdate';

const state = new Map(); // itemId --> itemData

const itemTemplates = {
  sac: sacItems,
  ...sactionalItems,
  squattoman,
  throwPillow,
};

function moveCameraTarget() {
  const { player, scene } = window.threekit.api;
  const targetId = player.cameraController.getOrbitTargetNodeId();
  const allItems = Array.from(getItems().keys());
  const targetPosition =
    player.cameraController.getBoundingSphereOrDefault(allItems).center;
  scene.set(
    { id: targetId, plug: 'Transform', property: 'translation' },
    targetPosition
  );
}

export function frameSceneAroundLoneItem() {
  const itemIds = Array.from(getItems().keys());
  if (itemIds.length === 1) {
    return resetCameraPosition(itemIds[0]);
  }
}

function resetCameraPosition(itemId) {
  return new Promise((resolve) => {
    // we have to wait until the item model is loaded to calculate the framing box
    // TODO: search for a clearer solution
    function checkForModelBeingLoaded() {
      const node = window.threekit.api.scene.get({
        id: itemId,
        evalNode: true,
      });

      if (!node) {
        return;
      }

      if (node.getBoundingBox()?.getSize()?.length() > 0) {
        resolve(frameScene(Array.from(getItems().keys())));
      } else {
        setTimeout(checkForModelBeingLoaded, 500);
      }
    }

    // if this is the first item
    if (getItems().size === 1) {
      checkForModelBeingLoaded();
    }
  });
}

export async function internalAddItem(item) {
  if (!item) throw new Error('No item provided');

  const { type, key, configuration, position, id, sku } = item;
  const itemType = itemTemplates[type];
  if (!itemType) throw new Error(`Unknown sactional item of type ${type}`);

  const itemData = itemType[key];
  if (!itemData)
    throw new Error(
      `Unsupported item key '${key}' for type ${type} itemType = `,
      itemType
    );

  // const { assetId } = itemData;
  // if (!assetId) throw new Error('No asset id found for this item');

  const parentId = await getRootId();
  const assetId = MODELS_IDS_BY_NAME[type]?.[key];
  let assetSearch = {};

  if (assetId) {
    assetSearch = { assetId };
  } else {
    assetSearch = {
      query: {
        metadata: { collection: type, product: key },
      },
    };
  }

  const plugs = {
    Null: [
      {
        type: 'Model',
        asset: assetSearch,
      },
    ],
  };

  // If predefined position, set it on the initial node data. Otherwise, the
  // item will be auto positioned. Autopositioning depends on model parts
  // (particularly the connectors) be loaded. This means the node gets
  // initialized at the origin momentarily before getting autopositioned. To
  // avoid this visual jump, we keep the item hidden until after it is
  // autopositioned.

  if (position) {
    plugs.Transform = [{ type: 'Transform', ...position }];
  } else plugs.Properties = [{ type: 'ModelProperties', visible: false }];

  const nodeId = window.threekit.api.scene.addNode(
    {
      id,
      type: 'Model',
      name: `${type}_${key}`,
      plugs,
    },
    parentId
  );

  const itemId = nodeId; // using a new variable here for clarity of intent

  // Really item should only be added to state once it is fully set up, so there
  // will never be a partial/invalid set of state for the item. Otherwise
  // anything accessing the item during this setup can cause misbehave, such as
  // selection, but state needs to be set before calling the applyConfigurationFn.

  state.set(itemId, { id: itemId, ...item, modelId: itemId });

  if (configuration) {
    // const applyConfigurationFn = itemType[key].applyConfiguration;

    if (configuration && configuration.fabric) {
      const sceneFabric = baFabricToSceneConfig(configuration.fabric);
      await setSceneConfiguration(sceneFabric);
    }

    // if (applyConfigurationFn) await applyConfigurationFn(state.get(itemId));
  }

  // TODO: Handle toggling of shadow plane for newly added sacs or other items
  // This is part of a general issue where any newly added items should inherit
  // any desired global previous we have applied to items.

  await window.threekit.api.player.evaluateSceneGraph();

  // ModelProperties in plugs have to be true so evaluateSceneGraph() can get
  // the instance, but we don't want it to show before it gets to the right
  // position
  // window.threekit.api.scene.set(
  //   { id: itemId, plug: 'Properties', property: 'visible' },
  //   false
  // );
  if(type !== 'Bed Collection'){
    await registerConnectorsForItem(itemId, type, key, position);
  } else {
    window.threekit.api.scene.set(
      { id: itemId, plug: 'Properties', property: 'visible' },
      false
    );
    if (!position) {
      await initItemPlacement(itemId);
    }
  }

  // Now that we have connectors loaded, we can autoplace the item, and then
  // make it visible since it is now in the right location
  // if (!position) {
  //   await initItemPlacement(itemId);
  // }

  window.threekit.api.scene.set(
    { id: itemId, plug: 'Properties', property: 'visible' },
    true
  );

  // resetCameraPosition(itemId);

  moveCameraTarget();

  if (configuration) {
    for (const configKey in configuration) {
      const currentConfig = { [configKey]: configuration[configKey] };
      await setConfigOnModel(itemId, currentConfig);
    }
  }

  addToMeasurableTargets(itemId);
  setTimeout(updateMainSet, 0);
  return itemId;
}

export async function addItem(item) {
  const itemId = await internalAddItem(item);
  await clearSelection();
  await selectItem(itemId);
  // frameScene(Array.from(state.keys())); // with autoplacement of new items in view, let's try without framing
  await updateCapacity([item]);
  return itemId;
}

export async function configureItems(
  itemId,
  { sku, configuration, price, dPrice, color, type }
) {
  const item = state.get(itemId);
  if (!item) return;

  if (!!sku) {
    item.sku = sku;
    item.price = price;
    item.dPrice = dPrice;
    item.color = color;
    item.type = type;
  }
  item.configuration = {
    ...item.configuration,
    ...configuration,
  };

  // Previous approach of applying per-item configurations
  // return Promise.all(
  //   items.map(async (itemId) => {
  //     const { type, key } = state.get(itemId);
  //     const applyConfigurationFn = itemTemplates[type][key].applyConfiguration;
  //     if (applyConfigurationFn) {
  //       updateItemState(itemId, config);
  //       return applyConfigurationFn(state.get(itemId));
  //     }
  //   })
  // );

  // Global Scene configuration approach

  // items.forEach(async (itemId) => {
  //   updateItemState(itemId, config);
  // });

  // if (config && config.fabric) {
  //   const sceneFabric = baFabricToSceneConfig(config.fabric);
  //   await setSceneConfiguration(sceneFabric);
  // }
}

// Does not need to be async, but for consistency we are using Promise-based return values
// for our api
export async function removeItems(itemIds = []) {
  removeColliders(itemIds);
  await deselectItems(itemIds); // remove from selection state
  const items = await Promise.all(
    itemIds.map(async (itemId) => {
      const item = state.get(itemId);

      // Clear out attachment-related state.
      // Note: if we switched to using redux, this would be handled automatically
      // via the attachment's reducer.
      await itemWillBeRemoved(itemId);
      return item;
    })
  );

  items.map(async (item) => {
    const { id } = item;
    state.delete(id); // remove from items state

    window.threekit.api.scene.deleteNode(id); // remove from scenegraph
    removeFromMeasurableTargets(id);
    updateMainSet();
    return item;
  });

  moveCameraTarget();
  await updateCapacity(items, false);
}

export function getItems() {
  return new Map(state); // yes, this is only a shallow copy, but this should be fine
}

export function getItemIds() {
  return Array.from(getItems().keys());
}

export function getItem(itemId) {
  return state.get(itemId);
}

const updateItemState = (itemId, config) => {
  const item = state.get(itemId);
  // if (!item.configuration) item.configuration = config;
  // else {
  Object.entries(config).forEach(([key, value]) => {
    if (typeof value === 'object')
      item.configuration[key] = { ...item.configuration[key], ...value };
    else item.configuration[key] = value;
  });
  // }
};

// Get the ids of all non-shadow plane PolyMeshes for the given set of items
export const getMeshesForItems = async (itemIds, options = {}) => {
  const { excludeBackPillows, excludeAccessories } = options;

  const filteredNodeIds = [];

  const filter = ({ id, name, type }) => {
    // Back Pillows are not included in the height calculation
    if (excludeBackPillows && name === 'Back Pillow') return false;
    if (type === 'PolyMesh' && !isShadowPlane(name)) filteredNodeIds.push(id);
    return true;
  };

  await Promise.all(
    itemIds
      .filter((id) => {
        const { type } = getItem(id);
        if (excludeAccessories && isAccessory(id)) return false;
        if (
          type === 'sac' ||
          type === 'squattoman' ||
          (excludeBackPillows && type === 'seat')
        )
          return true;
        filteredNodeIds.push(id);
        return false;
      })
      .map((id) => traverseHierarchy(id, filter, true))
  );

  return filteredNodeIds;
};

export const isAccessory = (itemId) =>
  ['coaster', 'drinkHolder', 'rollArmDrinkHolder', 'table'].includes(
    getItem(itemId).type
  );

// Sactional accessories that are strewn about the floor or in a separate
// holding area should not be included in the calculation of the framing of the
// scene for the thumbnail
export const getNonAccessories = async (itemIds) => {
  return itemIds.filter((id) => !isAccessory(id));
};

export const getSeatingCapacity = (items) =>
  items.reduce((acc, { type, key }) => {
    const { seatingCapacity } = itemTemplates[type][key];
    if (seatingCapacity) acc += seatingCapacity;
    return acc;
  }, 0);

window.getItems = getItems;
