import { traverseHierarchy } from './sceneGraph';

export function getBoundingBoxAPI() {
  const { THREE } = window.threekit.api;
  const IDENTITY = new THREE.Matrix4();
  const tmpVector3a = new THREE.Vector3();
  const tmpVector3b = new THREE.Vector3();

  function getUntransformedBoundingBox(store, id, targetBBox) {
    targetBBox.makeEmpty();
    const sceneGraph = store.get('sceneGraph');
    const evalNode = sceneGraph.evaluatedNodes[id];
    if (!evalNode) return;
    const { type } = sceneGraph.nodes[id];
    switch (type) {
      case 'PolyMesh':
        const { mesh } = evalNode.PolyMesh;
        if (mesh) targetBBox.copy(mesh.boundingBox);
        return;
      case 'Shape':
        const { shape } = evalNode.Shape;
        if (shape) {
          const box2 = shape.boundingBox;
          targetBBox.set(
            tmpVector3a.set(box2.min.x, box2.min.y, 0),
            tmpVector3b.set(box2.max.x, box2.max.y, 0)
          );
        }
        return;
      case 'ShadowPlane':
        const size = 1; // evalNode.ShadowPlane.size;
        targetBBox.min.set(-size, 0, -size).divideScalar(2);
        targetBBox.max.set(+size, 0, +size).divideScalar(2);
        return;
      case 'Measurement':
        evalNode.Measurement.getClippingBBox_world(targetBBox);
        return;
      case 'VrayMesh':
        const { min, max } = evalNode.VrayMesh;
        targetBBox.min.copy(min);
        targetBBox.max.copy(max);
        return;
      // case 'Sprite':
      //   Only needed for clip planes so an infinitely small point works just fine, because both near/far clip and Sprites are flat within the camera's z direction
      //   If we add a 'z' component to the pivot we will need to consider it here though
      default: // skip materials, etc.
        if (!evalNode.Transform) return;
        // lights, etc.: single point but not empty
        targetBBox.min.set(0, 0, 0);
        targetBBox.max.set(0, 0, 0);
        return;
    }
  }

  function getBoundingBoxTransform(store, id) {
    const sceneGraph = store.get('sceneGraph');
    const evalNode = sceneGraph.evaluatedNodes[id];
    if (sceneGraph.nodes[id].type === 'Measurement') return IDENTITY;
    return evalNode.worldTransform;
  }

  /**
   * Returns the bounding box of a node.
   *
   * @public
   * @param {String} id The uuid of a node
   *
   * @example
   *
   * var nodeId = scene.find({ name: 'Gear' });
   * var boundingBox = scene.getNodeBoundingBox(nodeId);
   *
   */
  const getNodeBoundingBox = (() => {
    const tmpInvRelativeTransform = new THREE.Matrix4();
    const tmpTransform = new THREE.Matrix4();
    const tmpBox = new THREE.Box3();
    return (store, id, options = {}) => {
      var _a, _b;
      let optionalInvRelativeTransform =
        options.relativeTransform &&
        tmpInvRelativeTransform.getInverse(options.relativeTransform);
      const layer = options.layer || null;
      const tag = options.tag || null;
      const nodeBB =
        (_a = options.targetBoundingBox) !== null && _a !== void 0
          ? _a
          : new THREE.Box3();
      nodeBB.makeEmpty();

      function include(childId) {
        getUntransformedBoundingBox(store, childId, tmpBox);
        if (!tmpBox.isEmpty()) {
          const transform = getBoundingBoxTransform(store, childId);
          nodeBB.union(
            tmpBox.applyMatrix4(
              optionalInvRelativeTransform
                ? tmpTransform
                    .copy(transform)
                    .premultiply(optionalInvRelativeTransform)
                : transform
            )
          );
        }
      }

      const { nodes } = store.get('sceneGraph');
      if (!options.ignoreChildren) {
        // build bbox of all descendants, relative to id's transform
        traverseHierarchy(
          store,
          id,
          (_b = options.visibleOnly) !== null && _b !== void 0 ? _b : true,
          (node) => {
            const traversedId = node.id;
            if (
              !nodes[traversedId] ||
              !nodes[traversedId].plugs ||
              !nodes[traversedId].plugs.Properties
            ) {
              // If hierarchy includes 'Objects', Properties will not exist.
              return;
            }
            if (layer) {
              const propPlug = nodes[traversedId].plugs.Properties;
              const props = propPlug && propPlug[0];
              // skip node if it's not in the requested layer
              if (props && props.layer && props.layer !== layer) return;
            }
            if (tag) {
              const propPlug = nodes[traversedId].plugs.Properties;
              if (!propPlug) {
                return;
              }
              const tags = propPlug.find(plug => plug.type === 'Tags');
              if (!tags) {
                return;
              }
              if (!tags?.tags?.includes?.(tag)) {
                return;
              }
            }
            if (options.whitelistTypes && options.whitelistTypes.length) {
              if (options.whitelistTypes.indexOf(node.type) < 0) return;
            }
            include(traversedId);
          }
        );
      } else include(id);
      return nodeBB.isEmpty() ? null : nodeBB;
    };
  })();
  const getNodesBoundingBox = (() => {
    const tmpBox3 = new THREE.Box3();
    return (store, ids, options = {}) => {
      var _a;
      const targetBB =
        (_a = options.targetBoundingBox) !== null && _a !== void 0
          ? _a
          : new THREE.Box3();
      targetBB.makeEmpty();
      ids.reduce((bb, id) => {
        const nodeBB = getNodeBoundingBox(store, id, {
          ...options,
          targetBoundingBox: tmpBox3,
        });
        if (nodeBB) bb.union(nodeBB);
        return bb;
      }, targetBB);
      return targetBB.isEmpty() ? null : targetBB;
    };
  })();

  function getNodeListSize(list, options){
    const { store } = window.threekit.api.player;
    const box = getNodesBoundingBox(store, list, options);
    if (!box) {
      return null;
    }
    const size = new THREE.Vector3();
    box.getSize(size);

    return {
      width: m2cm(size.x),
      height: m2cm(size.y),
      depth: m2cm(size.z),
    };
  }

  function getMeasureBoxSize(list) {
    return getNodeListSize(list, { visibleOnly: false, tag: 'Measurement' });
  }

  function getModelSize(list){
    return getNodeListSize(list);
  }

  return {
    getNodesBoundingBox,
    getMeasureBoxSize,
    getModelSize
  };
}

function m2cm(value){
  return (value * 100).toFixed(0);
}
