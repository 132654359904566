import { getRootId } from '../../helpers';
import { MEASUREMENT_NAME, data } from './constants';

const rulerSettings = {
  active: true,
  color: { r: 0, g: 0, b: 0 },
  decimals: 0,
  endEndcapShape: 3,
  endcapRatio: 4,
  fontCSSSpecifier: 'Frutiger LT Std',
  fontSize: 3,
  fontType: 3,
  includeChildren: true,
  labelHorizontal: 1,
  labelRotation: 0,
  labelVertical: 0,
  labelWorldRelative: false,
  lineStyle: 0,
  lineThickness: 0.17,
  space: 'local',
  startEndcapShape: 3,
  unit: 'cm',
  xEnabled: true,
  xLabel: '$L cm',
  xOffset: { x: 0, y: 0.1, z: 0.1 },
  xPositioning: 'top',
  yEnabled: true,
  yLabel: '$L cm',
  yOffset: { x: 0.1, y: 0, z: 0.1 },
  yPositioning: 'left',
  zEnabled: true,
  zLabel: '$L cm',
  zOffset: { x: 0.1, y: 0.1, z: 0 },
  zPositioning: 'top',
};

function addBoxDimensions(targets, parentId) {
  const { scene } = window.threekit.api;

  const t = targets.map((id) => {
    const child = window.threekit.api.scene.get({
      from: id,
      tags: ['Measurement'],
      hierarchical: true,
    });
    if (child) {
      return child.id;
    }
    return id;
  });

  const id = scene.addNode(
    {
      type: 'Measurement',
      name: MEASUREMENT_NAME,
      plugs: {
        Measurement: [
          {
            type: 'BoundingBox',
            targets: t,
            ...rulerSettings,
          },
        ],
        Properties: [
          {
            type: 'Default',
            visible: data.showDimensions,
          },
        ],
      },
    },
    parentId
  );

  // scene.set({id, plug: 'Properties', property: 'visible'}, data.showDimensions);
  return id;
}

async function addBoxDimensionsToRoot(targets) {
  const parentId = await getRootId();
  return addBoxDimensions(targets, parentId);
}

export { addBoxDimensionsToRoot, addBoxDimensions };
