import { getItems } from '../modules/items';
import { clearSelection, selectItem } from '../modules/selection';
import { isShadowPlane } from '../helpers';

let pendingSelection = false;

export const selectionTool = {
  key: 'lovesacSelect',
  label: 'Select',
  active: true,
  enabled: true,
  handlers: {
    click: async (ev) => {
      if (pendingSelection) return;
      pendingSelection = true;

      await clearSelection();

      if (!ev.hitNodes || !ev.hitNodes.length) {
        pendingSelection = false;
        return;
      }
      const hit = ev.hitNodes[0]; // for now always just check first hit - do we care about clicking objects behind the first hit?
      if (!hit.hierarchy.length) {
        pendingSelection = false;
        return;
      }

      // check name of leaf node to see if it's a shadow plane mesh
      const { name } = hit.hierarchy[hit.hierarchy.length - 1];
      if (isShadowPlane(name)) {
        pendingSelection = false;
        return;
      }

      const items = getItems();

      // find Model hit that matches one of our showroom items search from
      // bottom up, because an accessory may be parented to a side, and a click
      // on an accessory should select it, not the side
      const hitItem = hit.hierarchy
        .slice()
        .reverse()
        .find(({ nodeId }) => items.has(nodeId));

      // the mouse clicked something, but it is not a selectable showroom item
      // (could be floor plane, etc)
      if (!hitItem) {
        pendingSelection = false;
        return;
      }

      await selectItem(hitItem.nodeId);
      pendingSelection = false;
    },
  },
};
