// This file is intended to hold functions for automated placement of items
// based on various logic. Ex. attach to a random, available target.

import { getItems, getItem, getSeatBackPillow } from '../items';
import {
  getWorldTransform,
  getRandomInt,
  getRootId,
  isSeat,
  isSide,
} from '../../helpers';
import { selectItem } from '../selection';
import {
  getValidConnectorTargetsForItem,
  attach,
  getConnectorData,
  getAttachment,
  getNamedConnectorForItem,
  getAttachmentsForConnector,
  hasBackPillow,
  getConnectorMap,
  makeValidAttachments,
  sortSeatsAttachments,
  getOppositeSeatSide,
  isSameSeatSideType,
  isDeepSeatSide,
  PREFERRED_SEAT_SIDES,
  getConnectorsForItem,
  isValidConnection, updateMainSet,
} from './attachments';
import { pointCameraAtPosition, frameScene } from '../camera';
import { syncColliderTransforms } from '../collision';

function getCameraFrustum() {
  const { api } = window.threekit;
  // see https://stackoverflow.com/questions/24877880/three-js-check-if-object-is-in-frustum

  const sceneId = api.scene.get({ id: '709987e6-2243-4b57-a39b-783285ec0b5f' });
  // api.player.camera.frameBoundingSphere(api.scene.find({ name: 'Floor' }), {
  //   x: 0,
  //   y: -0.5,
  //   z: 0,
  // })
  // api.camera.frameBoundingSphere('709987e6-2243-4b57-a39b-783285ec0b5f', {
  //   x: 0,
  //   y: -0.5,
  //   z: -1,
  // });

  const camId = api.player.cameraController.getActiveCamera();
  const iid = api.player.instanceId;
  const cameras = api.scene.getAll({ from: iid, type: 'Camera' });

  const camWorldTransform = api.scene.get({ id: camId, evalNode: true })
    .worldTransform;
  const invWorld = new api.THREE.Matrix4().getInverse(camWorldTransform);
  const projectionMatrix = api.player.translator.getCameraProjectionMatrix(
    new api.THREE.Matrix4()
  );
  const frustum = new api.THREE.Frustum().setFromProjectionMatrix(
    new api.THREE.Matrix4().multiplyMatrices(projectionMatrix, invWorld)
  );

  return frustum;
}

function nodeInFrustum(nodeId, frustum) {
  const worldTransform = getWorldTransform(nodeId);
  const worldPos = new window.threekit.api.THREE.Vector3().setFromMatrixPosition(
    worldTransform
  );
  return frustum.containsPoint(worldPos);
}

function getItemsInView(cameraFrustum) {
  const itemsInView = Array.from(getItems().keys()).filter((id) =>
    nodeInFrustum(id, cameraFrustum)
  );
  //   itemsInView.map(selectItem); // for debugging
  return itemsInView;
}

async function attachPreferredConnectorPair(
  targetMap,
  { srcConnectorFilter, targetItemFilter, targetConnectorFilter, onAttach }
) {
  for (const srcConnectorId of Object.keys(targetMap)) {
    const srcConnectorData = getConnectorData(srcConnectorId);
    if (!srcConnectorFilter || srcConnectorFilter(srcConnectorData)) {
      for (const [targetItemId, targetConnectorIds] of Object.entries(
        targetMap[srcConnectorId]
      )) {
        if (
          !targetItemFilter ||
          targetItemFilter(
            getItem(targetItemId),
            getItem(srcConnectorData.owner)
          )
        ) {
          for (const targetConnectorId of targetConnectorIds) {
            if (!targetConnectorFilter ||
              targetConnectorFilter(
                getConnectorData(targetConnectorId),
                srcConnectorData
              )) {
              // eslint-disable-next-line no-await-in-loop
              if (await attach(srcConnectorId, targetConnectorId)) {
                if(onAttach) {
                  onAttach(srcConnectorId, targetConnectorId);
                }
                return true;
              }
            }
          }
        }
      }
    }
  }
  return false;
}

async function attachSeatToPreferredSeat(targetMap) {
  for (const [targetItemId, targetConnectors] of Object.entries(
    Object.values(targetMap)[0]
  )) {
    if (getItem(targetItemId).type !== 'seat') continue;
    const { seats } = sortSeatsAttachments(targetItemId);
    if (seats && seats.length >= 2) continue;

    let targetConnectorId;
    if (!seats) {
      targetConnectorId = targetConnectors.find((connectorId) => {
        const { seatSide } = getConnectorData(connectorId);
        return seatSide === 'left' || seatSide === 'right';
      });
    } else if (seats.length === 1) {
      const targetSeatSide = getOppositeSeatSide(
        seats[0].thisConnector.seatSide
      );
      targetConnectorId = targetConnectors.find((connectorId) => {
        const { seatSide } = getConnectorData(connectorId);
        return seatSide === targetSeatSide;
      });
    }
    if (targetConnectorId) {
      const { seatSide: targetSeatSide, name: targetName } = getConnectorData(
        targetConnectorId
      );
      const srcSeatSide = getOppositeSeatSide(targetSeatSide);
      const srcName = `${srcSeatSide}${targetName.split(targetSeatSide)[1]}`;

      const srcConnectorId = Object.keys(targetMap).find((connectorId) => {
        const { name } = getConnectorData(connectorId);
        return name === srcName;
      });
      // eslint-disable-next-line no-await-in-loop
      if (await attach(srcConnectorId, targetConnectorId)) return true;
    }
  }
  return false;
}

async function attachSideToPreferredSeatSide(targetMap) {
  const srcConnectorId = Object.keys(targetMap).find(
    (connectorId) => getConnectorData(connectorId).type === 'sideClampSlot'
  );
  if (srcConnectorId) {
    const { owner } = getConnectorData(srcConnectorId);
    const { key } = getItem(owner);
    if (key !== 'rollArm') {
      for (const [targetItemId, targetConnectors] of Object.entries(
        targetMap[srcConnectorId]
      )) {
        if (hasBackPillow(targetItemId)) continue;
        const preferredSeatSides = getPreferredSeatSideForSide(targetItemId);

        const seatSidesForSideType = preferredSeatSides.filter((seatSide) =>
          key === 'deep' ? isDeepSeatSide(seatSide) : !isDeepSeatSide(seatSide)
        );

        for (const seatSide of seatSidesForSideType) {
          const targetConnectorId = targetConnectors.find(
            (connectorId) => getConnectorData(connectorId).seatSide === seatSide
          );
          if (
            targetConnectorId &&
            // eslint-disable-next-line no-await-in-loop
            (await attach(srcConnectorId, targetConnectorId))
          )
            return true;
        }
      }
    }

    for (const [targetItemId, targetConnectors] of Object.entries(
      targetMap[srcConnectorId]
    )) {
      if (!hasBackPillow(targetItemId)) continue;
      const preferredSeatSides = getPreferredSeatSideForSide(targetItemId);

      const seatSidesForSideType =
        key === 'deep'
          ? preferredSeatSides.filter((seatSide) => isDeepSeatSide(seatSide))
          : preferredSeatSides;

      // Make sure a Standard or Roll Arm Side attached on a deep seat side is
      // aligned properly with the perceived back of the seat
      const [preferredNonDeep] = preferredSeatSides.filter(
        (seatSide) => !isDeepSeatSide(seatSide)
      );
      for (const seatSide of seatSidesForSideType) {
        const targetConnectorIds = targetConnectors.filter(
          (connectorId) => getConnectorData(connectorId).seatSide === seatSide
        );
        const connectorName =
          isDeepSeatSide(seatSide) &&
          preferredNonDeep &&
          `${seatSide}${
            preferredNonDeep.charAt(0).toUpperCase() + preferredNonDeep.slice(1)
          }`;
        for (const targetConnectorId of targetConnectorIds) {
          if (
            (!connectorName ||
              connectorName === getConnectorData(targetConnectorId).name) &&
            // eslint-disable-next-line no-await-in-loop
            (await attach(srcConnectorId, targetConnectorId))
          )
            return true;
        }
      }
    }
  }
  return false;
}

async function attachSeatToPreferredSide(targetMap) {
  for (const [targetItemId, targetConnectors] of Object.entries(
    Object.values(targetMap)[0]
  )) {
    const { type, key } = getItem(targetItemId);
    if (type !== 'side' || key === 'rollArm') continue;

    const targetConnectorId = targetConnectors[0];
    const srcSeatSide = key === 'deep' ? 'left' : 'back';
    const srcConnectorId = Object.keys(targetMap).find((connectorId) => {
      const { seatSide } = getConnectorData(connectorId);
      return seatSide === srcSeatSide;
    });
    // eslint-disable-next-line no-await-in-loop
    if (await attach(srcConnectorId, targetConnectorId)) return true;
  }
  return false;
}

function getParallelSeatSidesMap(seatSideA, seatSideB) {
  if (seatSideA === seatSideB) {
    if (isDeepSeatSide(seatSideA)) return { front: 'back', back: 'front' };
    return { left: 'right', right: 'left' };
  }
  if (seatSideA === getOppositeSeatSide(seatSideB)) {
    if (isDeepSeatSide(seatSideA)) return { front: 'front', back: 'back' };
    return { left: 'left', right: 'right' };
  }
  if (
    (seatSideA === 'right' && seatSideB === 'front') ||
    (seatSideA === 'left' && seatSideB === 'back')
  ) {
    return { left: 'back', right: 'front' };
  }
  if (
    (seatSideA === 'right' && seatSideB === 'back') ||
    (seatSideA === 'left' && seatSideB === 'front')
  ) {
    return { left: 'front', right: 'back' };
  }
  if (
    (seatSideA === 'front' && seatSideB === 'right') ||
    (seatSideA === 'back' && seatSideB === 'left')
  ) {
    return { back: 'left', front: 'right' };
  }
  if (
    (seatSideA === 'back' && seatSideB === 'right') ||
    (seatSideA === 'front' && seatSideB === 'left')
  ) {
    return { front: 'left', back: 'right' };
  }
}

function getPreferredSeatSideForSide(seatItemId, prevSeatSide) {
  const { key } = getItem(seatItemId);
  // For a wedge seat, the preferred seat side is always the back
  if (key === 'wedge') return ['back'];
  const { seats, sides } = sortSeatsAttachments(seatItemId);
  // Seat is attached to no other seats
  if (!seats) {
    // If seat side is attached to 3 sides, the 3 corresponding seat sides are
    // the preferred sides (prevents auto-placement of sides all around a seat)
    if (sides) {
      if (sides.length === 3)
        return sides.map(({ thisConnector }) => thisConnector.seatSide);
      if (sides.length === 1) {
        const { seatSide: occupiedSeatSide } = sides[0].thisConnector;
        if (!isDeepSeatSide(occupiedSeatSide))
          return PREFERRED_SEAT_SIDES.filter(
            (seatSide) => seatSide !== getOppositeSeatSide(occupiedSeatSide)
          );
      }
    }
    // Otherwise, all seat sides are preferred seat sides
    return PREFERRED_SEAT_SIDES;
  }
  // If a seat is attached to 4 other seats, it has no preferred seat sides
  if (seats.length === 4) return [];
  // If a seat is attached to 3 other seats, the one seat side that is not
  // attached to a seat is the preferred seat side
  if (seats.length === 3) {
    const seatSides = seats.map(({ thisConnector }) => thisConnector.seatSide);
    const sidelessSide = PREFERRED_SEAT_SIDES.find(
      (val) => !seatSides.includes(val)
    );
    return [sidelessSide];
  }
  if (seats.length === 2) {
    const seatSideA = seats[0].thisConnector.seatSide;
    const seatSideB = seats[1].thisConnector.seatSide;
    // If a seat side has 2 seats attached perpendicular to one another, both
    // seat sides not attached to seats are the preferred seat sides
    if (seatSideA !== getOppositeSeatSide(seatSideB))
      return PREFERRED_SEAT_SIDES.filter(
        (seatSide) => seatSide !== seatSideA && seatSide !== seatSideB
      );
  }
  // This is a recursive call
  if (prevSeatSide) {
    const prevSeat = seats.find(
      ({ thisConnector }) => thisConnector.seatSide === prevSeatSide
    );

    const { thisConnector, otherConnector } = prevSeat;
    const { seatSide: thisSeatSide, name: thisName } = thisConnector;
    const { seatSide: otherSeatSide, name: otherName } = otherConnector;
    // Seat side is oriented differently than the previous seat, the preferred
    // seat side is the seat side that is aligned with a seat side on the
    // previous seat
    if (!isSameSeatSideType(thisSeatSide, otherSeatSide)) {
      const preferredSeatSides = [];
      if (thisSeatSide === 'left' || thisSeatSide === 'right') {
        preferredSeatSides.push(
          getOppositeSeatSide(thisName.split(thisSeatSide)[1].toLowerCase())
        );
      }
      if (thisSeatSide === 'front' || thisSeatSide === 'back') {
        const connectorPosition = otherName
          .split(otherSeatSide)[1]
          .toLowerCase();

        if (connectorPosition !== thisSeatSide)
          preferredSeatSides.push(otherSeatSide);
        else preferredSeatSides.push(getOppositeSeatSide(otherSeatSide));
      }
      // If seat is only attached to one seat, the seat side opposite this
      // attachment is also a preferred seat side
      if (seats.length === 1)
        preferredSeatSides.push(getOppositeSeatSide(prevSeatSide));

      return preferredSeatSides;
    }
  }
  // The only seat this seat is attached to is the previous one and they are
  // oriented the same way
  if (prevSeatSide && seats.length === 1) {
    // If the seat has attached sides, the corresponding seat sides as well as
    // the seat side opposite the attachment to the previous seat are the
    // preferred seat sides
    if (sides) {
      const perpendicularSides = sides.reduce((acc, { thisConnector }) => {
        if (thisConnector.seatSide !== getOppositeSeatSide(prevSeatSide))
          acc.push(thisConnector.seatSide);
        return acc;
      }, []);
      if (perpendicularSides.length)
        return [...perpendicularSides, getOppositeSeatSide(prevSeatSide)];
    }
    // Otherwise all seat seats except for the one on which the previous seat is
    // attached are the preferred seat sides
    return PREFERRED_SEAT_SIDES.filter((seatSide) => seatSide !== prevSeatSide);
  }
  // Seat is attached to one seat and getPreferredSeatSideForSide has not been
  // called on that seat
  if (seats.length === 1) {
    const { thisConnector, otherConnector } = seats[0];
    const { seatSide: thisSeatSide } = thisConnector;
    const { seatSide: otherSeatSide, owner } = otherConnector;

    // If the seat has attached sides, the corresponding seat sides as well as
    // the seat side opposite the attachment to the other seat are the
    // preferred seat sides
    if (sides) {
      const perpendicularSides = sides.reduce((acc, side) => {
        if (side.thisConnector.seatSide !== getOppositeSeatSide(thisSeatSide))
          acc.push(side.thisConnector.seatSide);
        return acc;
      }, []);

      if (perpendicularSides.length)
        return [...perpendicularSides, getOppositeSeatSide(thisSeatSide)];
    }

    // Otherwise, no conclusions can be drawn, call getPreferredSeatSideForSide
    // on the attached seat
    const seatSides = getPreferredSeatSideForSide(owner, otherSeatSide);
    const parallelSeatSidesMap = getParallelSeatSidesMap(
      thisSeatSide,
      otherSeatSide
    );
    // Preferred seat sides are those aligned with the preferred seat sides
    // returned from the recursive call along with the seat side opposite the
    // attached seat
    const preferredSeatSides = seatSides.reduce((acc, seatSide) => {
      const parallelSeatSide = parallelSeatSidesMap[seatSide];
      if (parallelSeatSide) acc.push(parallelSeatSide);
      return acc;
    }, []);

    if (preferredSeatSides.length)
      preferredSeatSides.push(getOppositeSeatSide(thisSeatSide));
    return preferredSeatSides;
  }
  // This seat is attached to 2 seats in parallel, one of which has already been
  // "assessed"
  if (prevSeatSide) {
    const nextSeat = seats.find(
      ({ thisConnector }) => thisConnector.seatSide !== prevSeatSide
    );

    const { thisConnector, otherConnector } = nextSeat;
    const { seatSide: thisSeatSide } = thisConnector;
    const { seatSide: otherSeatSide, owner } = otherConnector;

    // If the seat has attached sides, the corresponding seat sides are the
    // preferred seat sides
    if (sides) {
      const preferredSeatSides = sides.reduce((acc, side) => {
        acc.push(side.thisConnector.seatSide);
        return acc;
      }, []);
      if (preferredSeatSides.length) return preferredSeatSides;
    }

    // Otherwise, no conclusions can be drawn, call getPreferredSeatSideForSide
    // on the attached seat that is not the previous seat
    const seatSides = getPreferredSeatSideForSide(owner, otherSeatSide);
    const parallelSeatSidesMap = getParallelSeatSidesMap(
      thisSeatSide,
      otherSeatSide
    );
    // Preferred seat sides are those aligned with the preferred seat sides
    // returned from the recursive call
    return seatSides.reduce((acc, seatSide) => {
      const parallelSeatSide = parallelSeatSidesMap[seatSide];
      if (parallelSeatSide) acc.push(parallelSeatSide);
      return acc;
    }, []);
  }

  // This seat is attached to 2 seats, neither of which have been "assessed"
  const {
    thisConnector: thisConnectorA,
    otherConnector: otherConnectorA,
  } = seats[0];
  const { owner: ownerA, seatSide: seatSideA } = otherConnectorA;
  const {
    thisConnector: thisConnectorB,
    otherConnector: otherConnectorB,
  } = seats[1];
  const { owner: ownerB, seatSide: seatSideB } = otherConnectorB;

  // If the seat has attached sides, the corresponding seat sides are the
  // preferred seat sides
  if (sides) {
    const preferredSeatSides = sides.reduce((acc, side) => {
      acc.push(side.thisConnector.seatSide);
      return acc;
    }, []);

    if (preferredSeatSides.length) return preferredSeatSides;
  }

  // Otherwise, no conclusions can be drawn, call getPreferredSeatSideForSide
  // on both attached seats
  const seatSidesA = getPreferredSeatSideForSide(ownerA, seatSideA);
  const parallelSeatSidesMapA = getParallelSeatSidesMap(
    thisConnectorA.seatSide,
    seatSideA
  );
  const preferredSeatSidesA = seatSidesA.reduce((acc, seatSide) => {
    const parallelSeatSide = parallelSeatSidesMapA[seatSide];
    if (parallelSeatSide) acc.push(parallelSeatSide);
    return acc;
  }, []);

  const seatSidesB = getPreferredSeatSideForSide(ownerB, seatSideB);
  const parallelSeatSidesMapB = getParallelSeatSidesMap(
    thisConnectorB.seatSide,
    seatSideB
  );
  const preferredSeatSidesB = seatSidesB.reduce((acc, seatSide) => {
    const parallelSeatSide = parallelSeatSidesMapB[seatSide];
    if (parallelSeatSide) acc.push(parallelSeatSide);
    return acc;
  }, []);

  // Preferred seat sides are the intersection of seat sides aligned with the
  // preferred seat sides returned from the two recursive calls
  return preferredSeatSidesA.filter((seatSide) =>
    preferredSeatSidesB.includes(seatSide)
  );
}

async function attachToPreferredTarget(srcItemId, targetMap) {
  const item = getItem(srcItemId);
  if (item.key === 'wedge') {
    return (
      // 1) Try to attach side of wedge seat to deep side of a standard/storage
      //    seat
      (await attachPreferredConnectorPair(targetMap, {
        srcConnectorFilter: ({ seatSide }) =>
          seatSide === 'right' || seatSide === 'left',
        targetItemFilter: (targetItem) => isSeat(targetItem) && targetItem.key !== 'wedge',
        targetConnectorFilter: ({ seatSide }) =>
          seatSide === 'right' || seatSide === 'left',
      })) ||
      // 2) Try to attach side of wedge seat to the side of another wedge seat
      //    such that they are oriented the same way
      (await attachPreferredConnectorPair(targetMap, {
        srcConnectorFilter: ({ seatSide }) =>
          seatSide === 'right' || seatSide === 'left',
        targetItemFilter: ({ key }) => key === 'wedge',
        targetConnectorFilter: (
          { seatSide: targetSeatSide, name: targetName },
          { seatSide: srcSeatSide, name: srcName }
        ) =>
          targetSeatSide === getOppositeSeatSide(srcSeatSide) &&
          targetName.split(targetSeatSide)[1] === srcName.split(srcSeatSide)[1],
      })) ||
      // 3) Try to attach back of wedge seat to a side
      attachPreferredConnectorPair(targetMap, {
        srcConnectorFilter: ({ seatSide }) => seatSide === 'back',
        targetItemFilter: (targetItem) =>
          isSide(targetItem) && targetItem.key === 'standard',
      })
    );
  }

  if (isSide(item)) {
    // Try to attach side to seat
    return (
      (await attachSideToPreferredSeatSide(targetMap)) ||
      // 2) If 1) is not possible, prefer attachment of side to another side such
      //    that the two sides are oriented the same way
      attachPreferredConnectorPair(targetMap, {
        srcConnectorFilter: ({ type, owner }) =>
          type === 'sideAttachment' && getItem(owner).key !== 'rollArm',
        targetItemFilter: ({ type, key: targetkey }, { key: srcKey }) =>
          type === 'side' && targetkey === srcKey,
      })
    );
  }
  // Prefer attachment of side accessories to sides without back pillows
  if (['rollArmDrinkHolder', 'drinkHolder', 'coaster'].includes(item.type)) {
    const [srcConnectorId] = Object.keys(targetMap);
    const targets = targetMap[srcConnectorId];

    for (const [sideId, [connector]] of Object.entries(targets)) {
      const sideClampSlot = getNamedConnectorForItem(sideId, 'front');
      if (
        !sideClampSlot.occupied &&
        // eslint-disable-next-line no-await-in-loop
        (await attach(srcConnectorId, connector))
      )
        return true;

      const [attachmentId] = getAttachmentsForConnector(sideClampSlot.id);
      const attachment = getAttachment(attachmentId);
      const { itemId: seatId, connectorName } = Object.values(attachment).find(
        ({ itemId }) => itemId !== sideId
      );
      // eslint-disable-next-line no-await-in-loop
      const backPillow = await getSeatBackPillow(seatId);
      if (
        !connectorName.includes(backPillow) &&
        // eslint-disable-next-line no-await-in-loop
        (await attach(srcConnectorId, connector))
      )
        return true;
    }
  }
  // Try attach table to a seat that has no sides on longer edge
  if (item.type === 'table') {
    const [srcConnectorId] = Object.keys(targetMap);

    if (srcConnectorId) {
      for (const [targetItemId, [targetConnectorId]] of Object.entries(
        targetMap[srcConnectorId]
      )) {
        // 1. Get connectors of target item
        const targetItemConnectors = getConnectorsForItem(targetItemId);

        // 2. Check if either of front or back seatClampSlot is occipied by a side
        const occupied = targetItemConnectors.some((connectorId) => {
          const connector = getConnectorData(connectorId);
          if (
            connector.type === 'seatClampSlot' &&
            /front|back/.test(connector.seatSide) &&
            connector.occupied
          ) {
            const occupant = getConnectorData(connector.occupied);
            if (occupant && occupant.owner) {
              const occupantOwner = getItem(occupant.owner);
              return occupantOwner.type === 'side';
            }
          } else return false;
        });

        // eslint-disable-next-line no-await-in-loop
        if (!occupied && (await attach(srcConnectorId, targetConnectorId)))
          return true;
      }
    }
  }
  if (isSeat(item)) {
    // 1) Try to attach seat to a standard side on its non-deep seat side or to a
    //    deep side on its deep seat side
    if (
      (await attachSeatToPreferredSide(targetMap)) ||
      // 2) Try to attach non-deep seat side to a non-deep seat side of a seat
      //    with no attached seats or same seat side type to seat side opposite
      //    the attached seat on a seat with one other attached seat
      (await attachSeatToPreferredSeat(targetMap)) ||
      // 3) Try to attach seat to any other seat such that seats are oriented the
      //    same way and their sides are aligned
      (await attachPreferredConnectorPair(targetMap, {
        targetItemFilter: (type) => isSeat({type}),
        targetConnectorFilter: (
          { seatSide: targetSeatSide, name: targetName },
          { seatSide: srcSeatSide, name: srcName }
        ) =>
          (srcSeatSide === targetSeatSide && srcName !== targetName) ||
          (srcSeatSide === getOppositeSeatSide(targetSeatSide) &&
            srcName === `${srcSeatSide}${targetName.split(targetSeatSide)[1]}`),
      }))
    ) {
      return true;
    }
  }

  if(item.type  === 'Eterno' || item.type === 'Levin') {
    if(await attachPreferredConnectorPair(targetMap, {
      targetConnectorFilter: (src, target) => {
        return (
          isValidConnection(src, target)
        );
      },
    })) {
      updateMainSet();
      return true
    }
    return false;
  }

  // Fall back on picking the first available target
  return attachPreferredConnectorPair(targetMap, {});
}

function placeRandomlyInFrontOfCamera(itemId, cameraFrustum) {
  const { api } = window.threekit;
  // no items in view to attach to. Place on floor in view if possible
  // try X meters in front of camera at y = 0?
  const camId = api.player.cameraController.getActiveCamera();
  const camWorldTransform = api.scene.get({ id: camId, evalNode: true })
    .worldTransform;
  const camDirection = new api.THREE.Vector3();
  camWorldTransform.extractBasis(
    new api.THREE.Vector3(),
    new api.THREE.Vector3(),
    camDirection
  );

  // Cam points along its neg Z axis
  camDirection.negate();
  // We want horizontal distance from camera
  camDirection.setY(0).normalize();

  const camPos = new api.THREE.Vector3().setFromMatrixPosition(
    camWorldTransform
  );

  // Place item a random # of meters horizontally away from camera on the floor
  const distance = getRandomInt(5, 15);

  const itemPos = camPos
    .clone()
    .setY(0)
    .addScaledVector(camDirection, distance);

  api.scene.set(
    { id: itemId, plug: 'Transform', property: 'translation' },
    itemPos
  );

  if (!cameraFrustum.containsPoint(itemPos)) {
    // api.player.cameraController.lookAtBoundingSphere([itemId]); // doesn't work because model is kept invisible until positioned
    // api.player.cameraController.controls.setCameraTarget(itemPos);
    // api.player.cameraController.controls.centerOnTarget();

    pointCameraAtPosition(itemPos);
  }
}

async function placeRandomlyOnFloor(itemId) {
  const sceneObjectsId = await getRootId();

  // const plugs = window.threekit.api.scene.get({
  // name: 'Floor',
  //   from: { id: sceneObjectsId },
  // }).plugs; // .PolyMesh[0];

  // setPosition set position
  // const { width, depth } = plugs.PolyMesh[0];
  const x = 1; // getRandomNumber(-width / 2 + 1, width / 2 - 1);
  const z = 1; // getRandomNumber(-depth / 2 + 1, depth / 2 - 1);

  const itemPos = new window.threekit.api.THREE.Vector3(x, 0, z);

  window.threekit.api.scene.set(
    { id: itemId, plug: 'Transform', property: 'translation' },
    itemPos
  );

  // Ensure updated world transform is recalculated so subsequent code sees it
  // correctly, in particular, syncColliderTransforms
  await window.threekit.api.player.evaluateSceneGraph();
  return itemPos;
}

export async function initItemPlacement(itemId) {
  const cameraFrustum = getCameraFrustum();
  let success = false;

  // 1) First, try to attach to an item in view
  // const itemsInView = getItemsInView(cameraFrustum).filter(
  //   (id) => id !== itemId
  // );
  // if (itemsInView.length) {
  const targetItems = [...getItems().values()].map(({id}) => id).filter(
    (id) => id !== itemId
  );

  const targetMap = getValidConnectorTargetsForItem(
      itemId,
      targetItems,
      true
    );

    // if a valid and available target exists in view, attach to it
    if (Object.keys(targetMap).length) {
      success = await attachToPreferredTarget(itemId, targetMap);
    }
  // }
  if (!success) {
    // 2) If that failed, place the object randomly. If it does not end up in
    //    frame, point camera at it.
    // placeRandomlyInFrontOfCamera(itemId, cameraFrustum);
    const items = getItems();

    if (items.size === 1) return; // no items, let new item be placed at default origin

    const itemPos = await placeRandomlyOnFloor(itemId);

    if (!cameraFrustum.containsPoint(itemPos)) {
      // pointCameraAtPosition(itemPos);

      await frameScene(Array.from(items.keys()));
    }
  }
  // Now attach to all other valid connectors that it aligns with
  // TODO - unify connector map and target map data structures so we can just
  // reuse targetMap here
  const connectorMap = getConnectorMap([itemId]);
  await makeValidAttachments(connectorMap);

  syncColliderTransforms([itemId]);
}

window.getCameraFrustum = getCameraFrustum;
window.getItemsInView = getItemsInView;
window.initItemPlacement = initItemPlacement;
