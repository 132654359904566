import {dimensionsApi} from "../../constants";
import {clearUpAttachmentData, registerAttachments, updateMainSet} from "./attachments";
import {initItemPlacement} from "./auto-place";
import {addCollidersForItem} from "../collision/collision";
import fetchDimensionsTable from "./fetchDimensionsTable";


export async function registerConnectorsForItem(itemId, type, key, position) {
  // await window.threekit.api.player.evaluateSceneGraph();
  await resizeItemToDeclaredDimensions(itemId, type, key, position);
  updateMainSet();
}

function setupColliders(itemId, min, max){
  addCollidersForItem(itemId, min, max);
}

export function resizeItemToDeclaredDimensions(itemId, collection, product, position) {
  const {scene, player} = window.threekit.api;
  // const size = new THREE.Vector3();
  window.threekit.api.scene.set(
    { id: itemId, plug: 'Properties', property: 'visible' },
    true
  );

  return Promise.all([
    fetchDimensionsTable(),
    waitForTheModelToLoad(itemId)
  ])
    .then(async ([rows, dms]) => {

      await clearUpAttachmentData(itemId);

      const scale = {x: 1, y: 1, z: 1};
      const declaredSize = rows.find(row => {
        return row.Collection_Name === collection && row.Product_Name === product
      });

      if (declaredSize && dms) {
        const {origin, size} = dms;
        if(declaredSize.Width){
          scale.x = declaredSize.Width / (size.width * 100);
        }

        if(declaredSize.Height){
          scale.y = declaredSize.Height / (size.height * 100);
        }

        if(declaredSize.Depth){
          scale.z = declaredSize.Depth / (size.depth * 100);
        }

        await registerAttachments(itemId);

        scene.set({
          id: itemId,
          plug: 'Transform',
          property: 'scale'
        }, scale);

        await player.evaluateSceneGraph();

        const min = {x: (origin.x - size.width/2) * scale.x + 0.01, z: (origin.z - size.depth/2) * scale.z + 0.01};
        const max = {x: (origin.x + size.width/2) * scale.x - 0.01, z: (origin.z + size.depth/2) * scale.z - 0.01};
        setupColliders(itemId, min, max);

      } else {
        // await createAttachmentPointsForItem(itemId, origin, {x:size.width, y: 0, z: size.depth}, itemData.attachmentPoints);
        // console.log('attachment-points', origin, size, itemData.attachmentPoints);
        // await registerAttachments(itemId);
        // console.log('register-attachment-points', itemId);
      }


      if (!position) {
        await initItemPlacement(itemId);
      }
    });
}

export function waitForTheModelToLoad(itemId) {
  const { THREE, scene } = window.threekit.api;
  const size = new THREE.Vector3();
  return new Promise((resolve) => {
    // we have to wait until the item model is loaded to calculate the framing box
    // TODO: search for a clearer solution
    function checkForModelBeingLoaded() {
      const node = scene.get({
        id: itemId,
        evalNode: true,
      });

      if (!node) {
        return;
      }

      const childId = scene.findNode({
        from: itemId,
        tags: ['Measurement'],
        hierarchical: true,
        evalNode: true,
      });

      if (childId) {
        const depth = scene.get({id: childId, plug: 'PolyMesh', property: 'depth'});
        const width = scene.get({id: childId, plug: 'PolyMesh', property: 'width'});
        const height = scene.get({id: childId, plug: 'PolyMesh', property: 'height'});
        const box = {depth, height, width};
        const translation = scene.get({id: childId, plug: 'Transform', property: 'translation'});

        resolve({
          size: box,
          origin: translation,
        });
      }
      // await player.evaluateSceneGraph()

      const boundingBox = node.getBoundingBox();

      if (boundingBox) {
        // resolve();
        boundingBox.getSize(size);
      }

      if (size?.length() > 0) {
        // if (child) {
        //   scene.set({
        //     id: child.id,
        //     plug: 'Properties',
        //     property: 'visible'
        //   }, false);
        // }

        resolve();
      } else {
        requestAnimationFrame(checkForModelBeingLoaded);
      }
    }

    checkForModelBeingLoaded();
  });
}
