// import { fabricMap } from './fabrics';
import { setConfigOnModel, getConfigOnModel } from '../../helpers';

async function applyConfiguration(item) {
  // const { id, type } = item;
  // const { fabric } = item.configuration;
  // if (fabric) {
  //   const { soft, hard, trim } = fabric;
  //   const threekitConfig = {};
  //   if (fabric.hasOwnProperty('soft'))
  //     threekitConfig.Soft = { assetId: fabricMap[soft] };
  //   if (fabric.hasOwnProperty('hard'))
  //     threekitConfig.Hard = { assetId: fabricMap[hard] };
  //   if (fabric.hasOwnProperty('trim')) {
  //     if (trim) threekitConfig.Trim = { assetId: fabricMap[trim] };
  //     else if (type === 'seat') {
  //       if (fabric.hasOwnProperty('soft'))
  //         threekitConfig.Trim = { assetId: fabricMap[soft] };
  //     } else if (type === 'side') {
  //       if (fabric.hasOwnProperty('hard'))
  //         threekitConfig.Trim = { assetId: fabricMap[hard] };
  //     }
  //   }
  //   return setConfigOnModel(id, threekitConfig);
  // }
}

export function setSeatBackPillow(seatItemId, backPillowValue) {
  return setConfigOnModel(seatItemId, { BackPillow: backPillowValue });
}

export async function getSeatBackPillow(seatItemId) {
  const config = await getConfigOnModel(seatItemId);
  return config && config.BackPillow;
}

export const sactionalItems = {
  Levin: {
    'Levin Fabric Chair': {
      assetName: 'Levin Fabric Chair',
      applyConfiguration,
    },
    'Levin Fabric Ottoman': {
      assetName: 'Levin Fabric Ottoman',
      applyConfiguration,
    },
    'Levin Fabric Wedge': {
      assetName: 'Levin Fabric Wedge',
      applyConfiguration,
    },
  },
  Eterno: {
    'Eterno Fabric Armless Sofa': {
      assetName: 'Eterno Fabric Armless Sofa',
      applyConfiguration,
    },
    'Eterno Fabric Left Arm Sofa': {
      assetName: 'Eterno Fabric Left Arm Sofa',
      applyConfiguration,
    },
    'Eterno Fabric Right Arm Sofa': {
      assetName: 'Eterno Fabric Right Arm Sofa',
      applyConfiguration,
    },
    ['Eterno Fabric Left Chaise']: {
      assetName: 'Eterno Fabric Left Chaise',
      applyConfiguration,
    },
    ['Eterno Fabric Right Chaise']: {
      assetName: 'Eterno Fabric Right Chaise',
      applyConfiguration,
    },
    'Eterno Modular Sofa Console': {
      assetName: 'Eterno Modular Sofa Console',
      applyConfiguration,
    },
    'Eterno Fabric Storage Ottoman': {
      assetName: 'Eterno Fabric Storage Ottoman',
      applyConfiguration,
    },
    ['Eterno Fabric Armless Wedge Sofa']: {
      assetName: 'Eterno Fabric Armless Wedge Sofa',
      applyConfiguration,
    },
  },
  'Bed Collection': {
    ['Bed Model']: {
      assetName: 'Bed Model',
      applyConfiguration,
    },
  },
  Colette: {
    ['Colette Wing Super King Headboard - 200 cm']: {
      assetName: 'Colette Wing Super King Headboard - 200 cm',
      applyConfiguration,
    },
    'Colette Wing Single Headboard - 120 cm': {
      assetName: 'Colette Wing Single Headboard - 120 cm',
      applyConfiguration,
    },
    'Colette Wing Queen Headboard - 155 cm': {
      assetName: 'Colette Wing Queen Headboard - 155 cm',
      applyConfiguration,
    },
    'Colette Wing King Headboard - 180 cm': {
      assetName: 'Colette Wing King Headboard - 180 cm',
      applyConfiguration,
    },
    'Colette Stripe Super King Headboard - 200 cm': {
      assetName: 'Colette Stripe Super King Headboard - 200 cm',
      applyConfiguration,
    },
    'Colette Stripe Single Headboard - 120 cm': {
      assetName: 'Colette Stripe Single Headboard - 120 cm',
      applyConfiguration,
    },
    'Colette Stripe Queen Headboard - 155 cm': {
      assetName: 'Colette Stripe Queen Headboard - 155 cm',
      applyConfiguration,
    },
    'Colette Stripe King Headboard - 180 cm': {
      assetName: 'Colette Stripe King Headboard - 180 cm',
      applyConfiguration,
    },
    'Colette Straight Super King Headboard - 200 cm': {
      assetName: 'Colette Straight Super King Headboard - 200 cm',
      applyConfiguration,
    },
    'Colette Straight Single Headboard - 120 cm': {
      assetName: 'Colette Straight Single Headboard - 120 cm',
      applyConfiguration,
    },
    'Colette Straight Single Headboard - 90 cm': {
      assetName: 'Colette Straight Single Headboard - 90 cm',
      applyConfiguration,
    },
    'Colette Straight Queen Headboard - 155 cm': {
      assetName: 'Colette Straight Queen Headboard - 155 cm',
      applyConfiguration,
    },
    'Colette Straight King Headboard - 180 cm': {
      assetName: 'Colette Straight King Headboard - 180 cm',
      applyConfiguration,
    },
    'Colette Ray Super King Headboard - 200 cm': {
      assetName: 'Colette Ray Super King Headboard - 200 cm',
      applyConfiguration,
    },
    'Colette Ray Single Headboard - 120 cm': {
      assetName: 'Colette Ray Single Headboard - 120 cm',
      applyConfiguration,
    },
    'Colette Ray Queen Headboard - 155 cm': {
      assetName: 'Colette Ray Queen Headboard - 155 cm',
      applyConfiguration,
    },
    'Colette Ray King Headboard - 180 cm': {
      assetName: 'Colette Ray King Headboard - 180 cm',
      applyConfiguration,
    },
    'Colette Ray Single Headboard - 120 cm': {
      assetName: 'Colette Ray Single Headboard - 120 cm',
      applyConfiguration,
    },
    'Colette Curve Super King Headboard - 200 cm': {
      assetName: 'Colette Curve Super King Headboard - 200 cm',
      applyConfiguration,
    },
    'Colette Curve Single Headboard - 120 cm': {
      assetName: 'Colette Curve Single Headboard - 120 cm',
      applyConfiguration,
    },
    'Colette Curve Queen Headboard - 155 cm': {
      assetName: 'Colette Curve Queen Headboard - 155 cm',
      applyConfiguration,
    },
    'Colette Curve King Headboard - 180 cm': {
      assetName: 'Colette Curve King Headboard - 180 cm',
      applyConfiguration,
    },
    'Colette Super King Bed Base - 200x210 cm': {
      assetName: 'Colette Super King Bed Base - 200x210 cm',
      applyConfiguration,
    },
    'Colette Single Bed Base - 90x200 cm': {
      assetName: 'Colette Single Bed Base - 90x200 cm',
      applyConfiguration,
    },
    'Colette Single Bed Base - 120x200 cm': {
      assetName: 'Colette Single Bed Base - 120x200 cm',
      applyConfiguration,
    },
    'Colette Velvet Queen Base - 155x205 cm': {
      assetName: 'Colette Velvet Queen Base - 155x205 cm',
      applyConfiguration,
    },
    'Colette King Bed Base - 180x210 cm': {
      assetName: 'Colette King Bed Base - 180x210 cm',
      applyConfiguration,
    },
  },
  Stellar: {
    ['Stellar Vox Single Headboard - 120 cm']: {
      assetName: 'Stellar Vox Single Headboard - 120 cm',
      applyConfiguration,
    },
    ['Stellar Vox Queen Headboard - 155 cm']: {
      assetName: 'Stellar Vox Queen Headboard - 155 cm',
      applyConfiguration,
    },
    ['Stellar Vox King Headboard - 180 cm']: {
      assetName: 'Stellar Vox King Headboard - 180 cm',
      applyConfiguration,
    },
    ['Stellar Ora Single Headboard - 120 cm']: {
      assetName: 'Stellar Ora Single Headboard - 120 cm',
      applyConfiguration,
    },
    ['Stellar Ora Queen Headboard - 155 cm']: {
      assetName: 'Stellar Ora Queen Headboard - 155 cm',
      applyConfiguration,
    },
    ['Stellar Ora King Headboard - 180 cm']: {
      assetName: 'Stellar Ora King Headboard - 180 cm',
      applyConfiguration,
    },
    ['Stellar Neo Single Headboard - 120 cm']: {
      assetName: 'Stellar Neo Single Headboard - 120 cm',
      applyConfiguration,
    },
    ['Stellar Neo Queen Headboard - 155 cm']: {
      assetName: 'Stellar Neo Queen Headboard - 155 cm',
      applyConfiguration,
    },
    ['Stellar Neo King Headboard - 180 cm']: {
      assetName: 'Stellar Neo King Headboard - 180 cm',
      applyConfiguration,
    },
    ['Stellar Max Single Headboard - 120 cm']: {
      assetName: 'Stellar Max Single Headboard - 120 cm',
      applyConfiguration,
    },
    ['Stellar Max Queen Headboard - 155 cm']: {
      assetName: 'Stellar Max Queen Headboard - 155 cm',
      applyConfiguration,
    },
    ['Stellar Max King Headboard - 180 cm']: {
      assetName: 'Stellar Max King Headboard - 180 cm',
      applyConfiguration,
    },
    ['Stellar Ark Single Headboard - 120 cm']: {
      assetName: 'Stellar Ark Single Headboard - 120 cm',
      applyConfiguration,
    },
    ['Stellar Ark Queen Headboard - 155 cm']: {
      assetName: 'Stellar Ark Queen Headboard - 155 cm',
      applyConfiguration,
    },
    ['Stellar Ark King Headboard - 180 cm']: {
      assetName: 'Stellar Ark King Headboard - 180 cm',
      applyConfiguration,
    },
    ['Stellar Ace Single Headboard - 120 cm']: {
      assetName: 'Stellar Ace Single Headboard - 120 cm',
      applyConfiguration,
    },
    ['Stellar Ace Queen Headboard - 155 cm']: {
      assetName: 'Stellar Ace Queen Headboard - 155 cm',
      applyConfiguration,
    },
    ['Stellar Ace King Headboard - 180 cm']: {
      assetName: 'Stellar Ace King Headboard - 180 cm',
      applyConfiguration,
    },
  },
  Hydraulic: {
    ['Stellar Vox Single Headboard - 120 cm']: {
      assetName: 'Stellar Vox Single Headboard - 120 cm',
      applyConfiguration,
    },
    ['Stellar Vox Queen Headboard - 155 cm']: {
      assetName: 'Stellar Vox Queen Headboard - 155 cm',
      applyConfiguration,
    },
    ['Stellar Vox King Headboard - 180 cm']: {
      assetName: 'Stellar Vox King Headboard - 180 cm',
      applyConfiguration,
    },
    ['Stellar Ora Single Headboard - 120 cm']: {
      assetName: 'Stellar Ora Single Headboard - 120 cm',
      applyConfiguration,
    },
    ['Stellar Ora Queen Headboard - 155 cm']: {
      assetName: 'Stellar Ora Queen Headboard - 155 cm',
      applyConfiguration,
    },
    ['Stellar Ora King Headboard - 180 cm']: {
      assetName: 'Stellar Ora King Headboard - 180 cm',
      applyConfiguration,
    },
    ['Stellar Neo Single Headboard - 120 cm']: {
      assetName: 'Stellar Neo Single Headboard - 120 cm',
      applyConfiguration,
    },
    ['Stellar Neo Queen Headboard - 155 cm']: {
      assetName: 'Stellar Neo Queen Headboard - 155 cm',
      applyConfiguration,
    },
    ['Stellar Neo King Headboard - 180 cm']: {
      assetName: 'Stellar Neo King Headboard - 180 cm',
      applyConfiguration,
    },
    ['Stellar Max Single Headboard - 120 cm']: {
      assetName: 'Stellar Max Single Headboard - 120 cm',
      applyConfiguration,
    },
    ['Stellar Max Queen Headboard - 155 cm']: {
      assetName: 'Stellar Max Queen Headboard - 155 cm',
      applyConfiguration,
    },
    ['Stellar Max King Headboard - 180 cm']: {
      assetName: 'Stellar Max King Headboard - 180 cm',
      applyConfiguration,
    },
    ['Stellar Ark Single Headboard - 120 cm']: {
      assetName: 'Stellar Ark Single Headboard - 120 cm',
      applyConfiguration,
    },
    ['Stellar Ark Queen Headboard - 155 cm']: {
      assetName: 'Stellar Ark Queen Headboard - 155 cm',
      applyConfiguration,
    },
    ['Stellar Ark King Headboard - 180 cm']: {
      assetName: 'Stellar Ark King Headboard - 180 cm',
      applyConfiguration,
    },
    ['Stellar Ace Single Headboard - 120 cm']: {
      assetName: 'Stellar Ace Single Headboard - 120 cm',
      applyConfiguration,
    },
    ['Stellar Ace Queen Headboard - 155 cm']: {
      assetName: 'Stellar Ace Queen Headboard - 155 cm',
      applyConfiguration,
    },
    ['Stellar Ace King Headboard - 180 cm']: {
      assetName: 'Stellar Ace King Headboard - 180 cm',
      applyConfiguration,
    },
  },

  Open: {
    'Eterno Fabric Storage Ottoman': {
      assetName: 'Eterno Fabric Storage Ottoman',
      applyConfiguration,
    },
    'Eterno Modular Sofa Console': {
      assetName: 'Eterno Modular Sofa Console',
      applyConfiguration,
    },
    'Colette Super King Bed Base - 200x210 cm': {
      assetName: 'Colette Super King Bed Base - 200x210 cm',
      applyConfiguration,
    },
    'Colette Single Bed Base - 90x200 cm': {
      assetName: 'Colette Single Bed Base - 90x200 cm',
      applyConfiguration,
    },
    'Colette Single Bed Base - 120x200 cm': {
      assetName: 'Colette Single Bed Base - 120x200 cm',
      applyConfiguration,
    },
    'Colette Velvet Queen Base - 155x205 cm': {
      assetName: 'Colette Velvet Queen Base - 155x205 cm',
      applyConfiguration,
    },
    'Colette King Bed Base - 180x210 cm': {
      assetName: 'Colette King Bed Base - 180x210 cm',
      applyConfiguration,
    },
    'Colette Wing Single Headboard - 120 cm': {
      assetName: 'Colette Wing Single Headboard - 120 cm',
      applyConfiguration,
    },
    'Colette Wing Queen Headboard - 155 cm': {
      assetName: 'Colette Wing Queen Headboard - 155 cm',
      applyConfiguration,
    },
    'Colette Stripe Single Headboard - 120 cm': {
      assetName: 'Colette Stripe Single Headboard - 120 cm',
      applyConfiguration,
    },
    'Colette Stripe Queen Headboard - 155 cm': {
      assetName: 'Colette Stripe Queen Headboard - 155 cm',
      applyConfiguration,
    },
    'Colette Straight Single Headboard - 120 cm': {
      assetName: 'Colette Straight Single Headboard - 120 cm',
      applyConfiguration,
    },
    'Colette Straight Queen Headboard - 155 cm': {
      assetName: 'Colette Straight Queen Headboard - 155 cm',
      applyConfiguration,
    },
    'Colette Ray Single Headboard - 120 cm': {
      assetName: 'Colette Ray Single Headboard - 120 cm',
      applyConfiguration,
    },
    'Colette Ray Queen Headboard - 155 cm': {
      assetName: 'Colette Ray Queen Headboard - 155 cm',
      applyConfiguration,
    },
    'Colette Curve Single Headboard - 120 cm': {
      assetName: 'Colette Curve Single Headboard - 120 cm',
      applyConfiguration,
    },
    'Colette Curve Queen Headboard - 155 cm': {
      assetName: 'Colette Curve Queen Headboard - 155 cm',
      applyConfiguration,
    },
    'Colette Wing Super King Headboard - 200 cm': {
      assetName: 'Colette Wing Super King Headboard - 200 cm',
      applyConfiguration,
    },
    'Colette Wing King Headboard - 180 cm': {
      assetName: 'Colette Wing King Headboard - 180 cm',
      applyConfiguration,
    },
    'Colette Stripe Super King Headboard - 200 cm': {
      assetName: 'Colette Stripe Super King Headboard - 200 cm',
      applyConfiguration,
    },
    'Colette Stripe King Headboard - 180 cm': {
      assetName: 'Colette Stripe King Headboard - 180 cm',
      applyConfiguration,
    },
    'Colette Straight Super King Headboard - 200 cm': {
      assetName: 'Colette Straight Super King Headboard - 200 cm',
      applyConfiguration,
    },
    'Colette Straight King Headboard - 180 cm': {
      assetName: 'Colette Straight King Headboard - 180 cm',
      applyConfiguration,
    },
    'Colette Ray Super King Headboard - 200 cm': {
      assetName: 'Colette Ray Super King Headboard - 200 cm',
      applyConfiguration,
    },
    'Colette Ray King Headboard - 180 cm': {
      assetName: 'Colette Ray King Headboard - 180 cm',
      applyConfiguration,
    },
    'Colette Curve Super King Headboard - 200 cm': {
      assetName: 'Colette Curve Super King Headboard - 200 cm',
      applyConfiguration,
    },
    'Colette Curve King Headboard - 180 cm': {
      assetName: 'Colette Curve King Headboard - 180 cm',
      applyConfiguration,
    },
  },
  // TODO: remove all products below
  seat: {
    test: {
      assetName: 'Test Seat',
      applyConfiguration,
    },
    standard: {
      assetName: 'Standard Seat',
      applyConfiguration,
    },
    storage: {
      assetName: 'Storage Seat',
      applyConfiguration,
    },
    wedge: {
      assetName: 'Wedge Seat',
      applyConfiguration,
    },
    standard_outdoor: {
      assetName: 'Outdoor Standard Seat',
      applyConfiguration,
    },
  },
  side: {
    standard: {
      assetName: 'Standard Side',
      applyConfiguration,
    },
    rollArm: {
      assetName: 'Roll Arm Side',
      applyConfiguration,
    },
    deep: {
      assetName: 'Deep Side',
      applyConfiguration,
    },
    standard_outdoor: {
      assetName: 'Outdoor Standard Side',
      applyConfiguration: async () => {},
    },
    deep_outdoor: {
      assetName: 'Outdoor Deep Side',
      applyConfiguration: async () => {},
    },
  },
  table: {
    hickory: {},
    greyAsh: {},
    darkWalnut: {},
  },
  coaster: {
    hickory: { assetName: 'Hickory Coaster' },
    greyAsh: { assetName: 'Grey Ash Coaster' },
    darkWalnut: { assetName: 'Dark Walnut Coaster' },
  },
  drinkHolder: {
    hickory: { assetName: 'Hickory Drink Holder' },
    greyAsh: { assetName: 'Grey Ash Drink Holder' },
    darkWalnut: { assetName: 'Dark Walnut Drink Holder' },
  },
  rollArmDrinkHolder: {
    hickory: { assetName: 'Hickory Roll Arm Drink Holder' },
    greyAsh: { assetName: 'Grey Ash Roll Arm Drink Holder' },
    darkWalnut: { assetName: 'Dark Walnut Roll Arm Drink Holder' },
  },
};
