import {
  attach,
  detachAll,
  getSortedDistancePairs,
  getConnectorData,
  getConnectionAngle,
  getConnectorMap,
  makeValidAttachments,
  rotateItems,
  getConnectorsForItem,
  isValidConnection,
} from '../modules/placement';
import { getSelection } from '../modules/selection';
import { getItem } from '../modules/items';
import { getWorldPosition, getWorldTransform, isShadowPlane } from '../helpers';
import { syncColliderTransforms, isColliding } from '../modules/collision';
import { updateMainSet } from '../modules/placement/attachments';
import { notifySelectionChanged } from '../modules/selection/selection';

const SNAP_DISTANCE_THRESHOLD = 0.3; // distance at which items position will jump to fulfill a potential attachment
const SNAP_ANGLE_THRESHOLD = 50; // 45 is just under the angle diff between the 2 wedge sides, preventing the user from snapping the piece back and forth on adjacent items

export const moveTool = {
  key: 'lovesacMove',
  label: 'Move',
  active: true,
  enabled: true,
  handlers: {
    drag: (ev) => {
      if (!ev.hitNodes || !ev.hitNodes.length) return false; // NB: must explicitly return false to release event for other tools to handle

      const selection = getSelection();
      const selectionArray = Array.from(selection);

      const hit = ev.hitNodes[0];
      if (!hit.hierarchy.length) return;

      // check name of leaf node to see if it's a shadow plane mesh
      const { name } = hit.hierarchy[hit.hierarchy.length - 1];
      if (isShadowPlane(name)) return false;

      const selectedHit = hit.hierarchy.find(({ nodeId }) =>
        selection.has(nodeId)
      );
      if (!selectedHit) return false;

      const worldStart = ev.hitNodes[0].intersection;

      const { api } = window.threekit;
      const { THREE } = api;

      // move along XZ plane where y is the y where the mouse drag initiated ie
      // whatever we clicked on to drag, drag everything along that point's xz
      // plane
      const movePlane = new THREE.Plane(
        new THREE.Vector3(0, 1, 0),
        -worldStart.y
      );

      const offsets = {};
      const originalTransforms = {};

      // We do not want to set each selected item's origin to the dragged to
      // point. We want to move them relative to the dragged point with the same
      // offset from the mouse as they had at the start of dragging. Otherwise,
      // for example, if you started dragging an item by its corner, its center
      // (local origin) would suddenly snap to the mouse
      selection.forEach((itemId) => {
        // This does not work for parented items, such as accessories. Need actual world transform
        // const itemWorldPos = api.scene.get({
        //   id: itemId,
        //   plug: 'Transform',
        //   property: 'translation',
        // });

        // Note: This works because in the drag handler we are setting item
        // transforms relative to worldspace, and:
        // a) for anything already parented to the root, their local transform
        // already === world transform
        // b) for an item parented to another (ex. accessory)), it will always
        // be detached back to the root when being moved anyway, and so once we
        // try to move it, it will already be in worldspace again
        const itemWorldPos = getWorldPosition(itemId);

        offsets[itemId] = new THREE.Vector3().subVectors(
          itemWorldPos,
          worldStart
        );

        const origin = new THREE.Matrix4();
        origin.copy(getWorldTransform(itemId));
        originalTransforms[itemId] = origin;
      });

      // reusable vectors for drag handler calcs
      const rayIntersection = new THREE.Vector3();
      const newItemPos = new THREE.Vector3();

      const connectorMap = getConnectorMap(selectionArray);
      const { srcElement } = ev.originalEvent;

      return {
        handle: async (ev) => {
          // Stop dragging when mouse is outside
          const { originalEvent } = ev;
          const { target } = originalEvent;
          if (srcElement !== target) return;

          // for now we don't care about using ev.hitNodes. We don't care
          // about dragging to other nodes (yet), just drag along the xz plane
          // initialized at drag start
          const targetWorldPos = ev.eventRay.ray.intersectPlane(
            movePlane,
            rayIntersection
          );
          // TODO: Only process drag logic if dragged amount would put
          // currently-attached connectors farther from each other than snap
          // threshold
          const allConnections = [];
          for (const id of selection) {
            // eslint-disable-next-line no-await-in-loop
            const connections = await detachAll(id, false); // FIXME: this would break any internal connections between selected pieces as well
            allConnections.push(...connections);
            api.scene.set(
              { id, plug: 'Transform', property: 'translation' },
              newItemPos.addVectors(targetWorldPos, offsets[id])
            );
          }

          if (allConnections.length) {
            updateMainSet();
          }

          // ensure updated world transform is recalculated so subsequent code
          // sees it corectly
          await api.player.evaluateSceneGraph();

          if (Object.entries(connectorMap).length) {
            const connectorPairs = getSortedDistancePairs(connectorMap);

            // FIXME: This will move only the item with the closest attachment
            // pair. This does not handle moving other items as well - will
            // become a problem when we support multi-selection

            // Look through pairs for the first one within snap distance that
            // successfully attaches
            let snappedPair = null;
            for (const pair of connectorPairs) {
              if (!(pair.distance < SNAP_DISTANCE_THRESHOLD)) break;

              const { src, target } = pair;
              const srcConnector = getConnectorData(src);
              const targetConnector = getConnectorData(target);

              const srcItem = getItem(srcConnector.owner);
              const targetItem = getItem(targetConnector.owner);
              // angle threshold exceptions:
              // 1) accessory - no point in snap angle thresghold
              // 2) sides - it is a better UX to allow sides to snap at any
              //    angle, since they only have one side/connector that they
              //    attach to seats with anyway. Limiting snap angle requires
              //    excessive rotations from user when placing many sides.
              if (!srcConnector.occupied && !targetConnector.occupied) {
                if (srcItem.type === 'Eterno' || targetItem.type === 'Levin') {
                  if (isValidConnection(srcConnector, targetConnector)) {
                    // eslint-disable-next-line no-await-in-loop
                    if (await attach(pair.src, pair.target)) {
                      snappedPair = pair;
                      break;
                    }
                  } else {
                    const draggedItemConnectors = getConnectorsForItem(
                      srcConnector.owner
                    );
                    const validConnector = draggedItemConnectors.find((id) => {
                      const candidate = getConnectorData(id);
                      return isValidConnection(candidate, targetConnector);
                    });
                    if (validConnector) {
                      // const srcPosition = getWorldPosition(pair.src);
                      // eslint-disable-next-line no-await-in-loop
                      if (await attach(validConnector, pair.target)) {
                        // const newPosition = getWorldPosition(pair.src);
                        snappedPair = pair;
                        break;
                      }
                    }
                  }
                } else if (
                  srcConnector.type.includes('Accessory') ||
                  (srcItem.type === 'side' && targetItem.type !== 'side') ||
                  getConnectionAngle(src, target) <= SNAP_ANGLE_THRESHOLD
                ) {
                  // We know what we're doing, await usage in this loop is
                  // intentional/fine.
                  // eslint-disable-next-line no-await-in-loop
                  if (await attach(pair.src, pair.target)) {
                    snappedPair = pair;
                    break;
                  }
                }
                // When dragging a seat towards a side, if rotating the side 180
                // degrees would result in a valid attachment, rotate the side.
                else if (
                  srcItem.type === 'seat' &&
                  targetItem.type === 'side' &&
                  180 - getConnectionAngle(src, target) <= SNAP_ANGLE_THRESHOLD
                ) {
                  // eslint-disable-next-line no-await-in-loop
                  await rotateItems([targetConnector.owner], 180);
                  // eslint-disable-next-line no-await-in-loop
                  if (await attach(pair.src, pair.target)) {
                    snappedPair = pair;
                    break;
                  }
                  // eslint-disable-next-line no-await-in-loop
                  await rotateItems([targetConnector.owner], 180);
                }
              }
            }

            // ensure updated world transform is recalculated so subsequent code
            // sees it corectly
            await api.player.evaluateSceneGraph();

            // Now that we've moved all items for the closest connector pair to
            // attach, re-evaluated all possible other connector pairs to see if
            // any line up
            if (snappedPair) {
              await makeValidAttachments(connectorMap);
              // eslint-disable-next-line no-await-in-loop
              updateMainSet();
            }

            // // attempt to reset offset on snap, so item doesn't keep getting
            // // placed back in the original offset position, causing some junping
            // // while dragging between attach points
            // selection.forEach((itemId)
            // => {const itemWorldPos = api.scene.get({id: itemId, plug:
            // 'Transform', property: 'translation',
            //   });
            //   offsets[itemId] = new THREE.Vector3().subVectors(itemWorldPos,
            //     targetWorldPos
            //   );
            // });
          }

          await api.player.evaluateSceneGraph();
          syncColliderTransforms(selectionArray);
        },
        async onEnd() {
          const translation = new THREE.Vector3();
          const rotation = new THREE.Euler();
          for (const id of selection) {
            const originalTransform = originalTransforms[id];
            if (isColliding(id) && originalTransform) {
              api.scene.set(
                { id, plug: 'Transform', property: 'translation' },
                translation.setFromMatrixPosition(originalTransform)
              );
              api.scene.set(
                { id, plug: 'Transform', property: 'rotation' },
                rotation.setFromRotationMatrix(originalTransform)
              );
            }
          }
          await api.player.evaluateSceneGraph();
          syncColliderTransforms(selectionArray);
          await makeValidAttachments(getConnectorMap(selectionArray))
          // set isDragged true for saving model position undo config
          notifySelectionChanged(true);
        },
      };
    },
  },
};
