import { updateCapacity } from '../capacity';
import { getBoundingBox, getWorldTransform } from '../../helpers';
import { getMeshesForItems } from '../items';
import { decomposeForTransformPlug } from './align';
import {
  detachAll,
  getConnectorMap,
  makeValidAttachments,
} from './attachments';
import {syncColliderTransforms, wouldCollide} from '../collision';
import {resolveCollisions} from "../collision/collision";

function rotateNodeAroundPoint(nodeId, worldPivot, axis, radians) {
  const { api } = window.threekit;

  const worldTransform = getWorldTransform(nodeId);

  const nodeToPivot = worldPivot.clone().negate();

  const nodeToTargetMat = new api.THREE.Matrix4().makeTranslation(
    nodeToPivot.x,
    nodeToPivot.y,
    nodeToPivot.z
  );
  const targetToNodeMat = new api.THREE.Matrix4().getInverse(nodeToTargetMat);

  const rotWorldMatrix = new api.THREE.Matrix4().makeRotationAxis(
    axis.normalize(),
    radians
  );

  const finalWorld = worldTransform
    .premultiply(nodeToTargetMat)
    .premultiply(rotWorldMatrix)
    .premultiply(targetToNodeMat);

  const { translation, rotation } = decomposeForTransformPlug(finalWorld);

  api.scene.set(
    { id: nodeId, plug: 'Transform', property: 'translation' },
    translation
  );
  api.scene.set(
    { id: nodeId, plug: 'Transform', property: 'rotation' },
    rotation
  );
}

export async function rotateAndSnap(itemIdList, degrees = 90) {
  await rotateItems(itemIdList, degrees);
  const nodeId = itemIdList[0];

  const worldTransform = getWorldTransform(nodeId);
  if (!wouldCollide(nodeId, worldTransform)) {
    return;
  }

  await resolveCollisions(nodeId, worldTransform);
}

export async function rotateItems(itemIds, degrees = 90) {
  const { api } = window.threekit;

  itemIds.forEach((id) => detachAll(id, false));

  const radians = degrees * api.THREE.Math.DEG2RAD;

  const polymeshIds = await getMeshesForItems(itemIds);
  const bbox = getBoundingBox(polymeshIds);
  const bboxCenter = bbox.getCenter(new api.THREE.Vector3());

  itemIds.forEach((id) => {
    rotateNodeAroundPoint(
      id,
      bboxCenter,
      new api.THREE.Vector3(0, 1, 0),
      radians
    );
  });
  await api.player.evaluateSceneGraph();

  const connectorMap = getConnectorMap(itemIds);
  await makeValidAttachments(connectorMap);
  syncColliderTransforms(itemIds);
  await updateCapacity();
}
