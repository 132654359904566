const mainSet = new Set();
window.mainSet = mainSet;

export function isInMainSet(itemId) {
  return mainSet.has(itemId);
}

export function addToMainSet(itemId) {
  mainSet.add(itemId);
}

export function removeFromMainSet(itemId) {
  mainSet.delete(itemId);
}

export function assignMainSet(values) {
  mainSet.clear();
  values.forEach(v => mainSet.add(v));
}

export function getMainSet() {
  return Array.from(mainSet.values());
}

export function getMainSetSize() {
  return mainSet.size;
}
