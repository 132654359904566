import { setFloor } from './modules/room';
import {
  addItem,
  configureItems,
  removeItems,
  getItems,
} from './modules/items';
import { rotateItems } from './modules/placement';
import { rotateCamera } from './modules/camera';
import { getThumbnail } from './modules/thumbnail';
import {
  saveConfiguration,
  getEditConfiguration,
  saveEditConfiguration,
} from './modules/persistence';
import { reset } from './init';
import { addEventListener } from './modules/eventListeners';
import {
  showMeasurement,
  getMeasurements,
} from './modules/measurement';
import { clearUpAttachmentPoints } from './modules/placement/attachments';
import {
  frameItems,
  frameSceneAroundItems, getGroupMeasurementList, setOpenState,
  updateMeasureTargets,
} from './modules/measurement/measurement';
import { frameSceneAroundLoneItem } from './modules/items/items';
import { deselectItems } from './modules/selection';
import {addBoxDimensionsToRoot} from "./modules/measurement/dimensions";
import {rotateAndSnap} from "./modules/placement/rotate";

export default {
  setFloor,
  addItem,
  configureItems,
  removeItems,
  getItems,
  rotateItems: rotateAndSnap,
  rotateCamera,
  getThumbnail,
  saveConfiguration,
  getEditConfiguration,
  saveEditConfiguration,
  reset,
  addEventListener,
  showMeasurement,
  updateMeasureTargets,
  getMeasurements,
  clearUpAttachmentPoints,
  frameSceneAroundItems,
  frameSceneAroundLoneItem,
  deselectItems,
  setOpenState,
  frameItems,
  getGroupMeasurementList,
  addBoxDimensionsToRoot,
  //   _: { configuratorState, selectItem }, // private functions to expose for testing/debugging
};
